import axios from "@/axios";
import { checkIdExist, storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/ig/users',{
  actions: {
    getUserMedia({ dispatch },{ id,organisationId,pageId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest(
          {
            url: `/api/ig/users/${id}/media`,
            params: {
              organisationId,
              pageId,
              force,
            },
          },
          !(force == true)
        )
        .then((d) => {
          return d.data;
        });
    },
    getUserTags({ dispatch },{ id,organisationId,pageId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest(
          {
            url: `/api/ig/users/${id}/tags`,
            params: {
              organisationId,
              pageId,
              force,
            },
          },
          !(force == true)
        )
        .then((d) => {
          return d.data;
        });
    },
    getUserConversations({ dispatch },{ id,organisationId,pageId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest(
          {
            url: `/api/ig/users/${id}/conversations`,
            params: {
              organisationId,
              pageId,
              force,
            },
          },
          !(force == true)
        )
        .then((d) => {
          return d.data;
        });
    },
    getUserStories(
      { dispatch },
      { id,organisationId,pageId,projection,force }
    ) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest(
          {
            url: `/api/ig/users/${id}/stories`,
            params: {
              organisationId,
              pageId,
              projection,
              force,
            },
          },
          !(force == true)
        )
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "FBIGUSER"
});

export default module;