import moment from "moment";
import i18nService from "../controllers/i18nService";
import { ObjectId } from "bson";

export function parseObjectId(val) {
  if (val == null) {
    return val;
  }
  try {
    return new ObjectId(val);
  } catch (e) {
    return val;
  }
}

export function formatDate(date, format, simple) {
  if (date == null) {
    return "-";
  }

  date = parseObjectId(date);
  if (date instanceof ObjectId) {
    date = date.getTimestamp();
  }

  if (simple) {
    if (moment(date).isAfter(moment(new Date()).startOf("d"))) {
      return moment(date).format(format ?? "LT");
    } else if (
      moment(date).isAfter(moment(new Date()).subtract(1, "d").startOf("d"))
    ) {
      return "yesterday " + moment(date).format(format ?? "LT");
    } else {
      return moment(date).format(format ?? "L LT");
    }
  }
  return moment(date).format(format ?? "L LT");
}

export function blockButtonEvent(e, block = true) {
  if (e) {
    if (e instanceof PointerEvent) {
      if (e.target != null) {
        const buttonElement =
          e?.target?.tagName == "BUTTON"
            ? e?.target
            : e?.target?.closest("button");
        if (buttonElement) {
          if (buttonElement.disabled == true && block == false) {
            setTimeout(() => {
              buttonElement.disabled = block;
            }, 400);
          } else {
            buttonElement.disabled = block;
          }
        }
      }
    }
  }
}

export function pruneObject(data) {
  if (data) {
    if (data instanceof Object) {
      if (!Array.isArray(data)) {
        data = Object.fromEntries(
          Object.entries(data)
            .map(([key, val]) => [key, pruneObject(val)])
            .filter(([a, val]) => val != null)
        );
        if (Object.values(data).length == 0) {
          return;
        }
      } else if (Array.isArray(data)) {
        data = data.map((val) => pruneObject(val)).filter((val) => val != null);
        if (data.length == 0) {
          return;
        }
      }
    } else {
      if (typeof data == "number" && isNaN(data)) {
        return;
      } else if (typeof data == "string" && data.trim().length == 0) {
        return;
      }
    }
    return data;
  }
  return;
}

export function displayObjectData(data, key = "", objectType = "basic") {
  data = pruneObject(data);
  if (data != null) {
    if (data instanceof Object) {
      if (!Array.isArray(data)) {
        const res = Object.entries(data)
          .filter((a) => a[1] != null)
          .sort((a, b) => {
            return a[0].localeCompare(b[0]);
          })
          .map(([key, val]) => {
            return `<label>${
              i18nService?.i18n?.global?.te(`model.${objectType}-${key}`)
                ? i18nService?.i18n?.global?.t(`model.${objectType}-${key}`)
                : key
            }</label><div class="value-container">${displayObjectData(
              val,
              key
            )}</div>`;
          })
          .join("");
        return `<div class="item-data">${res}</div>`;
      } else {
        return data
          .map((val) => {
            return `<div class="array-value-container">${displayObjectData(
              val,
              key
            )}</div>`;
          })
          .join("");
      }
    } else {
      if (typeof data == "number") {
        if (
          Number.isInteger(data) &&
          (/data_access_expires_at/.test(key) || /issued_at/.test(key)) &&
          moment
            .unix(data)
            .isBetween(moment("2000-01-01"), moment("2030-01-01"))
        ) {
          return formatDate(moment.unix(data));
        }
      }
      if (typeof data == "string") {
        if (jpegRegex.test(data)) {
          return `<img src="${data}" alt="img-${key}" class="data-image-urls"></img>`;
        }

        if (facebookUrlRegex.test(data)) {
          return `<a href="${data}" target="_blank">Facebook Link</a>`;
        }
        if (externalUrlRegex.test(data)) {
          const matches = externalUrlRegex.exec(data);
          return `<a href="${matches[0]}" target="_blank">${
            matches[1] ?? "link"
          }</a>`;
        }
        if (dateStringRegex.test(data)) {
          const matches = dateStringRegex.exec(data);
          return formatDate(moment(matches[0]), "LL");
        }
      }
      if (typeof data == "boolean") {
        if (data) {
          return '<i class="fa-solid fa-check"></i>';
        } else {
          return '<i class="fa-solid fa-xmark"></i>';
        }
      }

      return `${data}`;
    }
  }
  return "";
}

export function getItemDataType(data) {
  if (data != null && typeof data == "string") {
    data = data.trim().toLowerCase();
    if (externalUrlRegex.test(data)) {
      return "url";
    }
    if (/^image/i.test(data)) {
      return "image";
    }
    if (/^video/i.test(data)) {
      return "video";
    }
    if (/^audio/i.test(data)) {
      return "audio";
    }
  }
  return;
}

export const scriptTagRegex =
  /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script\s*>/gi;

export const dateStringRegex =
  /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(:?(:?\+\d{4})|(:?\.\d+([+-][0-2]\d:[0-5]\d|Z)))/;

export const jpegRegex = /.jpg\?+/;

export const facebookUrlRegex = /^https:\/\/.+\.facebook\.com/;
export const externalUrlRegex = /^(\s+)?https?:\/\/(\S+)\/?/;

export const fbMessageDataRegex =
  /\(https:\/\/facebook\.com\/(\d+)(?:\/(?:posts|videos)\/(\d+)(?:\/\?comment_id=(\d+))?)?\)/;
export const fbMessageGenericDataRegex = /\((https:\/\/facebook\.com\/.+\))$/;

export function generateFacebookLinkUrl(url) {
  if (url.startsWith("https:")) {
    return url;
  } else {
    return `https://www.facebook.com${url}`;
  }
}

export function translateText(x, prehead = "messages") {
  return `${
    i18nService.i18n.global.te(prehead + "." + x)
      ? i18nService.i18n.global.t(prehead + "." + x)
      : x
  }`;
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function isColorDark(red, green, blue) {
  return (red ?? 0) * 0.299 + (green ?? 0) * 0.587 + (blue ?? 0) * 0.114 > 186;
}

export async function manageFormSubmitButtons(event, submitFunc) {
  if (event != null) {
    if (event instanceof Event && event.target != null) {
      if (event.target?.disabled == true) {
        return;
      }
      event.target.disabled = true;
    }
    if (event instanceof SubmitEvent && event.submitter != null) {
      event.submitter.disabled = true;
    }
  }
  let res;
  if (submitFunc && typeof submitFunc == "function") {
    res = submitFunc();
  } else {
    res = submitFunc;
  }

  if (res && res instanceof Promise) {
    res = await res;
  }

  setTimeout(() => {
    if (event) {
      if (event instanceof Event && event.target != null) {
        event.target.disabled = false;
      }
      if (event instanceof SubmitEvent && event.submitter != null) {
        event.submitter.disabled = false;
      }
    }
  }, 200);
  return res;
}

const timeoutMap = {};

class TimeoutManager {
  constructor(fn, interval) {
    this.fn = fn;
    this.interval = interval;
    this.id = setTimeout(() => {
      this.completed = true;
      fn();
      clearTimeout(this.id);
    }, interval);
    this.cleared = false;
    this.completed = false;
  }

  clear() {
    this.cleared = true;
    clearTimeout(this.id);
  }
}

export function manageRefreshWebhookEvents(compId, fn, timeout = 500) {
  if (timeoutMap[compId] != null) {
    clearTimeout(timeoutMap[compId]);
  }

  if (
    timeoutMap[compId] == null ||
    timeoutMap[compId].completed == true ||
    timeoutMap[compId].cleared == true
  ) {
    timeoutMap[compId] = new TimeoutManager(fn, timeout);
  }
}

export function clearRefreshWebhookEvents(compId) {
  if (timeoutMap[compId] != null) {
    timeoutMap[compId].clear();
    delete timeoutMap[compId];
  }
}
