<template>
  <div class="form-component-container" v-if="displayFields && displayFields.length > 0">
    <form @submit.stop.prevent="(e) => submitForm(e)" v-if="displayValue && displayFields" autocomplete="off">
      <div class="form-body-container">
        <CustomFormInputGroup v-model="displayValue" :fields="displayFields"
          @changeFieldDisplay="(d) => updateFieldDisplay(d)">
          
          <template v-for="field in displayFields" :field="field.key" #[field.type]>
            <slot :name="field.type"></slot>
          </template>
        </CustomFormInputGroup>
      </div>
      <slot name="topFormInfo"></slot>
      <slot name="submitButton" v-if="showSubmitButton">
        <CustomButton class="form-submit-button" buttonType="submit" iconClass="fa-regular fa-circle-check"
          :disabled="disabled"></CustomButton>
      </slot>
    </form>
  </div>
</template>
<script>

export default {
  name: "CustomFormCmp",
  props: {
    initialValue: { type: Object,default: () => undefined },
    fields: { type: Array,default: () => undefined },
    showSubmitButton: { type: Boolean,default: () => true },
    disabled: { type: Boolean,default: () => false },
  },
  data() {
    return {
      displayValue: undefined,
      displayFields: undefined,
    };
  },
  watch: {
    initialValue(val) {
      if (this.initialValue) {
        this.displayValue = { ...this.initialValue };
      } else {
        this.displayValue = {};
      }
      this.initForm();
    },
    fields(val) {
      if (this.fields && Array.isArray(this.fields)) {
        this.displayFields = [...this.fields];
      } else {
        this.displayFields = [];
      }
      this.initForm();
    },
  },
  mounted() {
    this.displayValue = { ...(this.initialValue ?? {}) };
    this.displayFields = [...(this.fields??[])];
    this.initForm();
  },
  emits: ["formSubmit"],
  methods: {
    async initForm() {
      if (this.displayFields) {
        this.displayValue = Object.fromEntries(this.displayFields.map((x) => {
          return [
            x.key,
            this.displayValue && this.displayValue[x.key] != null
              ? this.displayValue[x.key]
              : undefined,
          ]
        }));
      }
    },
    updateFieldDisplay(data) {
      this.displayFields = data;
    },
    submitForm(e) {
      if (this.disabled == true) {
        return;
      }
      if (e?.stopPropagation && e?.stopImmediatePropagation) {
        e?.stopPropagation();
        e?.stopImmediatePropagation();
      }
      this.$emit("formSubmit",this.displayValue,e);
    },
  }
}
</script>

<style lang="scss" scoped>
.form-component-container {
  .form-submit-button {
    &::v-deep(button) {
      min-width: 3rem;
      align-items: center;
      justify-content: center;
      font-size: 1rem;

      &.success-button {
        background-color: rgba(var(--vs-success), .9);
      }

      &.success-button:disabled {
        background-color: rgba(var(--vs-success), .8);
      }

      &.success-button:hover {
        background-color: rgba(var(--vs-success), 1);
      }
    }
  }

  .form-body-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }
}
</style>