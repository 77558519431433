<template>
  <div ref="scroll-container" class="scrollable-content">
    <div v-if="paginateScroll && ((nextButtonEnable && displayReverse) || (prevButtonEnable && !displayReverse))"
      ref="top-scroll-part" class="scroll-parts">
      <CustomButton buttonClass="pagination-load-button" iconClass="fa-solid fa-chevron-up" @click="checkScrollNeed"
        :disabled="disabled"></CustomButton>
    </div>
    <div class="scroll-item-content">
      <slot></slot>
    </div>
    <div v-if="paginateScroll == false" class="pagination-component-container">
      <div class="pagination-container">
        <CustomButton buttonClass="page-options" buttonText="general.first" @click="changePage(0)"
          :disabled="!firstButtonEnable || disabled"></CustomButton>
        <CustomButton buttonClass="page-options" buttonText="general.prev" @click="clickPrevPage()"
          :disabled="!prevButtonEnable || disabled"></CustomButton>
        <div v-for="(n,index) in displayedPageList" :key="startDisplayIndex + index">
          <CustomButton :buttonClass="{ 'page-options': true,selected: startDisplayIndex + index == currentIndex }"
            :buttonText="startDisplayIndex + index + 1" @click="changePage(startDisplayIndex + index)"
            :disabled="disabled"></CustomButton>
        </div>
        <CustomButton buttonClass="page-options" buttonText="general.next" @click="clickNextPage()"
          :disabled="!nextButtonEnable || disabled"></CustomButton>
        <CustomButton buttonClass="page-options" buttonText="general.last" @click="changePage(maxPageCount - 1)"
          :disabled="!lastButtonEnable || disabled"></CustomButton>
      </div>
      <div class="pagination-counts">
        <label> {{ $i18n.t("general.total") }}</label>
        <CustomButton buttonClass="page-options" :buttonText="itemCount" :disabled="true"></CustomButton>
      </div>
      <div class="pagination-item-count-container">
        <label> {{ $i18n.t("general.itemPerPage") }}</label>
        <CustomButton :buttonClass="{ 'page-options': 1,selected: itemPerPage == 10 }" buttonText="10"
          @click="changeItemPerPage(10)" :disabled="disabled"></CustomButton>
        <CustomButton :buttonClass="{ 'page-options': 1,selected: itemPerPage == 20 }" buttonText="20"
          @click="changeItemPerPage(20)" :disabled="disabled"></CustomButton>
      </div>
    </div>
    <div v-else-if="paginateScroll && ((prevButtonEnable && displayReverse) || (nextButtonEnable && !displayReverse))"
      ref="bottom-scroll-part" class="scroll-parts">
      <CustomButton buttonClass="pagination-load-button" iconClass="fa-solid fa-chevron-down" @click="checkScrollNeed"
        :disabled="disabled"></CustomButton>
    </div>
  </div>
</template>



<script>
export default {
  name: "CustomPaginationCmp",
  props: {
    itemCount: { type: Number,default: () => 0 },
    pageIndex: { type: Number,default: () => 0 },
    pageItemCount: { type: Number,default: () => 0 },
    pageDisplayCount: { type: Number,default: () => 1 },
    pageOptionLength: { type: Number,default: () => 5 },
    displayReverse: { type: Boolean,default: () => false },
    paginateScroll: { type: Boolean,default: () => false },
    disabled: { type: Boolean,default: () => false },
  },
  data() {
    return {
      topIsVisible: false,
      bottomIsVisible: false,
      iterval: undefined,
      scrollIterval: undefined,
      currentIndex: 0,
      maxPageCount: 0,
      itemPerPage: 10,
      scrollItemPerPage: 10,
      displayedPageList: undefined,
      startDisplayIndex: 0,
      debugMode: true
    };
  },
  watch: {
    pageIndex(val) {
      if (val != null) {
        this.currentIndex = val;
      }
    },
    pageItemCount(val) {
      if (val != null) {
        this.itemPerPage = this.pageItemCount;
      }
    },
    itemCount() {
      this.updateDisplayedList();
    },
    maxPageCount(val) {
      if (val < 0) {
        this.maxPageCount = 0;
      }
    },
    itemPerPage(val) {
      if (val < 1) {
        this.itemPerPage = 1;
      }
      this.updateDisplayedList();
    },
    startDisplayIndex(val) {
      if (val < 0) {
        this.startDisplayIndex = 0;
      } else if (val > this.maxPageCount - this.displayedPageLength) {
        this.startDisplayIndex = this.maxPageCount - this.displayedPageLength;
      }
    },
    currentIndex(val) {
      if (val < 0) {
        this.currentIndex = 0;
      } else if (this.maxPageCount - 1 < val) {
        this.currentIndex = this.maxPageCount - 1;
      }
      this.updateDisplayedList();
    },
  },
  mounted() {
    if (this.pageIndex != null) {
      this.currentIndex = this.pageIndex;
    }
    if (this.pageItemCount != null) {
      this.itemPerPage = this.pageItemCount;
    }
    this.updateDisplayedList();
  },
  computed: {
    displayedPageLength() {
      const count =
        this.maxPageCount > this.pageOptionLength
          ? this.pageOptionLength
          : this.maxPageCount;
      return count > 0 ? count : 1;
    },
    prevButtonEnable() {
      return this.currentIndex > 0;
    },
    firstButtonEnable() {
      return this.currentIndex > 0;
    },
    nextButtonEnable() {
      return this.currentIndex + this.pageDisplayCount < this.calculateMaxPageCount();
    },
    lastButtonEnable() {
      return this.currentIndex + this.pageDisplayCount < this.calculateMaxPageCount();
    },
    scrollContainer() {
      return this.$refs['scroll-container'];
    }
  },
  emits: ["pageChanged","scrollChanged"],
  methods: {
    scrollToStart(item) {
      if (this.scrollIterval) {
        this.scrollIterval = null;
        clearTimeout(this.scrollIterval);
      }
      this.scrollIterval = setTimeout(() => {
        let y = 2;
        if (item == 'top') {
          if (this.$refs['top-scroll-part']) {
            const itemRec = this.$refs['top-scroll-part'].getBoundingClientRect();
            y = (itemRec.top + itemRec.height) + 2;
          } else {
            y = 2;
          }
          if (this.scrollContainer) {
            this.scrollContainer.scroll({
              top: y,
              behavior: 'smooth'
            });
          }
        } else if (item == 'bottom') {
          if (this.$refs['bottom-scroll-part']) {
            y = this.$refs['bottom-scroll-part'].getBoundingClientRect().top - 2;
          } else {
            if (this.scrollContainer) {
              y = this.scrollContainer.scrollHeight - 2;
            }
          }
          if (this.scrollContainer) {
            this.scrollContainer.scroll({
              top: y,
              behavior: 'smooth'
            });
          }
        }
      },2000);

    },
    checkScrollNeed(e) {
      if (this.paginateScroll != true) {
        return false;
      }
      if (this.iterval) {
        this.iterval = null;
        clearTimeout(this.iterval);
      }
      this.iterval = setTimeout(() => {
        if (this.$refs) {
          this.topIsVisible = this.isElementInViewport(this.$refs['top-scroll-part']);
          if (this.topIsVisible) {
            if (this.displayReverse) {
              this.clickNextPage()
            } else {
              this.clickPrevPage();
            }
          } else {
            this.bottomIsVisible = this.isElementInViewport(this.$refs['bottom-scroll-part']);
            if (this.bottomIsVisible) {
              if (this.displayReverse) {
                this.clickPrevPage();
              } else {
                this.clickNextPage()
              }
            }
          }
        }
      },200);
    },
    isElementInViewport(el) {
      if (el == null) {
        return false;
      }
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
      );
    },

    clickPrevPage() {
      if (this.prevButtonEnable != true) {
        return;
      }
      this.emitScrollChange(-1);
    },
    clickNextPage() {
      if (this.nextButtonEnable != true) {
        return;
      }
      this.emitScrollChange(1);
    },
    updateDisplayedList() {
      this.calculateMaxPageCount();
      this.displayedPageList = new Array(this.displayedPageLength);
      this.startDisplayIndex =
        this.currentIndex - Math.floor(this.displayedPageLength / 2);
    },
    changePage(val) {
      this.currentIndex = val;
      this.emitPageChange();
    },
    calculateMaxPageCount() {
      return (this.maxPageCount = Math.ceil(this.itemCount / this.itemPerPage));
    },
    changeItemPerPage(val) {
      this.itemPerPage = val;
      this.emitPageChange();
    },
    emitPageChange() {
      this.$emit("pageChanged",{
        pageIndex: this.currentIndex,
        limit: this.itemPerPage,
      });
    },
    emitScrollChange(direction) {
      let pageIndex;
      let pageDisplayCount;

      if (direction == 1) {
        pageDisplayCount = this.pageDisplayCount + 1;
        pageIndex = this.currentIndex;
      } else if (direction == -1) {
        pageDisplayCount = this.pageDisplayCount + 1;
        pageIndex = this.currentIndex - 1;
      }

      if (pageDisplayCount < 1) {
        pageDisplayCount = 1;
      } else if (pageDisplayCount > 5) {
        const dif = pageDisplayCount - 5;
        pageDisplayCount = 5;
        if (direction == 1) {
          pageIndex = pageIndex + dif;
        }
      }

      if (pageIndex < 0) {
        pageIndex = 0;
      }

      this.$emit("scrollChanged",{
        pageIndex,
        pageDisplayCount,
        itemPerPage: this.itemPerPage,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-container {
  display: flex;
}

.pagination-component-container {
  display: flex;
  align-items: center;

  flex-wrap: wrap;
  justify-content: space-around;

  overflow: auto;
  flex: 1 1 auto;
  &>*{
    padding:.2rem .3rem
  }
}

.pagination-container,
.pagination-counts,
.pagination-item-count-container {
  label {
    font-size: 0.7rem;
  }
}



.scrollable-content {
  .scroll-parts {
    min-height: 1rem;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: red, $alpha: .1);
  }


  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &>div {
    display: flex;
  }

  :deep(.pagination-load-button) {
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    border-radius: .3rem;
    min-width: 4rem;
    padding: .3rem;
    margin: .3rem auto;
    background-color: rgba($color: blue, $alpha: 0.6);
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
    color: white;

    &:hover {
      background-color: rgba($color: blue, $alpha: 0.8);
      box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.35);
      color: white;
    }

    &:disabled {
      background-color: rgba($color: blue, $alpha: 0.4);
    }
  }

  :deep(.page-options) {
    border: none;
    min-width: 2rem;
    padding: 0.2rem 0.4rem;
    color: blue;
    background-color: rgba($color: lightgrey, $alpha: 0.7);
    border-radius: 0.5rem;
    margin: 0.1rem;

    &.selected {
      background-color: rgba($color: blue, $alpha: 0.7);
      color: white;
    }

    &:disabled {
      color: rgba($color: blue, $alpha: 0.3);
      cursor: not-allowed;
    }
  }
}
</style>