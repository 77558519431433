import axios from "@/axios";

export default {
  namespaced: "true",
  state: {},
  getters: {},
  actions: {
    log(a, { data }) {
      // return axios
      //   .baseRequest(
      //     {
      //       url: "/logger",
      //       method: "POST",
      //       data: data,
      //       notifyStatus: false
      //     },
      //     false
      //   ).catch(() => {
      //     return;
      //   });
    },
  },
  mutations: {},
};
