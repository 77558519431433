import config from "../config";
import { store } from "../store";

const urlRegex = /(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:._]{1,256})/;

export const getHostName = (val) => {
	const matchs = String(val).match(urlRegex);
	return matchs && matchs.length > 1 ? matchs[1] : null;
};

export class IndexDBService {

	static DB_NAME = "socialors_db";
	static DB_VERSION = 1;
	static DB_STORE_NAMES = {
		USER: { version: 1 },
		ORGANISATION: { version: 1 },
		ORGANISATIONQUICKREPLY: { version: 1 },
		ORGANISATIONMEMBERSHIP: { version: 1 },
		NOTIFICATION: { version: 1 },
		CUSTOMER: { version: 1 },
		CHANNEL: { version: 1 },
		SOCIALCONTENTLABEL: { version: 1 },
		SOCIALCONTENTLABELOPTION: { version: 1 },
		AUTH: { version: 1 },
		WAWEBAPPLICATION: { version: 1 },
		WAWEBCHAT: { version: 1 },
		WAWEBCONTACT: { version: 1 },
		WAWEBMESSAGE: { version: 1 },
		WAWEBPHONE: { version: 1 },
		WAWEBSESSION: { version: 1 },
		WABA: { version: 1 },
		WACONTACT: { version: 1 },
		WAMESSAGE: { version: 1 },
		WAPHONE: { version: 1 },
		FBIGCOMMENT: { version: 1 },
		FBIGCONVERSATION: { version: 1 },
		FBIGCUSTOMERUSER: { version: 1 },
		FBIGMEDIA: { version: 1 },
		FBIGMESSAGE: { version: 1 },
		FBIGTAGGEDMEDIA: { version: 1 },
		IGUSER: { version: 1 },
		FBACCESSTOKEN: { version: 1 },
		FBAPPLICATION: { version: 1 },
		FBCOMMENT: { version: 1 },
		FBCONVERSATION: { version: 1 },
		FBMESSAGE: { version: 1 },
		FBPAGE: { version: 1 },
		FBPOST: { version: 1 },
		FBREACTION: { version: 1 },
		FBUSER: { version: 1 },
		FBLABEL: { version: 1 },
	};

	constructor() {
		if (!("indexedDB" in window)) {
			this.isConnected = false;
			this.supported = false;
			store.commit("appIndexDbChecked",{});
			return;
		}
		this.supported = true;
		const request = window.indexedDB.open(
			IndexDBService.DB_NAME,
			IndexDBService.DB_VERSION
		);
		request.onerror = () => {
			store.commit("appIndexDbChecked",{});
			// Exception
		};
		request.onsuccess = (event) => {
			store.commit("appIndexDbChecked",{});
			this.isConnected = true;
			this.db = event.currentTarget.result;
		};
		request.onupgradeneeded = (event) => {
			this.tryCreateCollections(event);
		};
	}

	tryCreateCollections(event) {
		Object.entries(IndexDBService.DB_STORE_NAMES).forEach(([store,object]) => {
			if (event.oldVersion < object.version) {
				var storeObj = event.currentTarget.result.createObjectStore(store,{
					keyPath: "key",
				});

				["_id"].forEach((k) => {
					storeObj.createIndex(k,k,{ unique: false });
				});
			}
		});
	}

	connectToStore(store,operation) {
		if (this.isConnected && IndexDBService.DB_STORE_NAMES[store]) {
			var tx = this.db.transaction(store,operation ? operation : undefined);
			const result = tx.objectStore(store);
			if (result) {
				return result;
			} else {
				throw new Error("NOT_VALID_STORE");
			}
		}
	}

	get(store,id,dataVersion) {
		return new Promise((res,rej) => {
			try {
				const key = `${id}_${dataVersion ?? "-"}`;
				var req = this.connectToStore(store)?.get(key);
				if (req == null) {
					rej("INDEXEDDB_NOT_READY");
					return;
				}
				req.onerror = (event) => {
					rej(event);
				};
				req.onsuccess = (event) => {
					if (event.target?.result) {
						const data = event.target.result;
						res(data.data);
					} else {
						rej({ error: event,message: "NOT_FOUND",req: "get",store });
					}
				};
			} catch (e) {
				rej(e);
			}
		});
	}

	add(store,data,dataVersion) {
		return new Promise((res,rej) => {
			if (data) {
				try {
					var addReq = this.connectToStore(store,"readwrite")?.add({
						key: `${data._id}_${dataVersion ?? "-"}`,
						_id: data._id,
						dataVersion,
						data
					});
					if (addReq == null) {
						rej("INDEXEDDB_NOT_READY");
						return;
					}
					addReq.onerror = (event) => {
						rej(event);
						return event;
					};
					addReq.onsuccess = (event) => {
						const response = {
							data: event.target.result,
							isUpdated: false,
							isNew: true
						};
						res(response);
						return response;
					};
				} catch (e) {
					rej(e);
				}
			} else {
				rej({ data,store,key: data });
			}
		});
	}

	addOrUpdate(store,data,dataVersion) {
		return this.get(store,data._id,dataVersion)
			.then(() => this.update(store,data,dataVersion))
			.catch(() => this.add(store,data,dataVersion).catch(() => null))
			.then(() => this.get(store,data._id,dataVersion));
	}

	update(store,data,dataVersion) {
		return new Promise((res,rej) => {
			if (data) {
				return this.get(store,data.key)
					.then((content) => {
						const uValue = { ...content,...data };
						var updateReq = this.connectToStore(store,"readwrite")?.put(
							{
								key: `${data._id}_${(dataVersion ?? "-")}`,
								_id: data._id,
								dataVersion,
								data: uValue
							}
						);
						if (updateReq == null) {
							rej("INDEXEDDB_NOT_READY");
							return;
						}
						updateReq.onerror = (e) => {
							rej(e);
							return;
						};
						updateReq.onsuccess = (event) => {
							res(event.target.result);
							return;
						};
						return;
					})
					.catch((e) => {
						rej(e);
					});
			} else {
				rej({ data,store,key: data });
			}
		});
	}

	delete(store,indexVal) {
		return new Promise((res,rej) => {
			try {
				var dbStore = this.connectToStore(store,"readwrite");
				const index = dbStore.index('_id');
				let keyRng;
				if (indexVal) {
					keyRng = IDBKeyRange.only(indexVal);
				}
				var req = index?.openKeyCursor(keyRng);
				if (req == null || store == null) {
					rej("INDEXEDDB_NOT_READY");
					return;
				}
				req.onerror = (event) => {
					rej(event);
				};
				req.onsuccess = () => {
					var cursor = req.result;
					if (cursor) {
						dbStore.delete(cursor.primaryKey);
						cursor.continue();
					} else {
						res(true)
					}
				};
			} catch (e) {
				rej(e);
			}
		});
	}

	removeAllData() {
		return Promise.all(
			Object.keys(IndexDBService?.DB_STORE_NAMES ?? {})
				.map((k) => new Promise((res,rej) => {
					var deleteReq = this.connectToStore(k,"readwrite")?.clear();
					if (deleteReq == null) {
						rej("INDEXEDDB_NOT_READY");
						return;
					}
					if (deleteReq) {
						deleteReq.onsuccess = (event) => {
							res(event.target.result);
						};
						deleteReq.onerror = (e) => {
							rej(e);
						};
					} else {
						rej({ error: "deleteReq null" });
					}
				}).catch((e) => {
					console.warn("INDEX_DB_REMOVE_ALL_DATA_ERROR",e);
				})
				)
		)
			.then(() =>
				new Promise((res,rej) => {
					const delReq = window.indexedDB.deleteDatabase(
						IndexDBService.DB_NAME
					);
					if (delReq) {
						delReq.onerror = function (e) {
							rej(e);
						};
						delReq.onsuccess = function (e) {
							res(e);
						};
					}
					rej({});
				}).catch(() => { })
			)
			.catch(() => { });
	}
}

class LocalStorageService {
	constructor() {
		if (!this.data || this.data["url"] !== getHostName(window.location.href)) {
			this.data = {};
		}
		this.data = {
			...this.data,
			initialTableData: {},
			url: getHostName(window.location.href)
		};
	}

	set data(val) {
		localStorage.setItem(`socialors_${config.backendUrl ?? ''}`,JSON.stringify(val));
	}

	get data() {
		try {
			return JSON.parse(localStorage.getItem(`socialors_${config.backendUrl ?? ''}`));
		} catch (e) {
			return {};
		}
	}

	set initialTableData(val) {
		if (this.data == null) {
			this.data = {};
		}
		this.data = { ...this.data,initialTableData: val ?? {} };
	}

	get initialTableData() {
		return this.data?.initialTableData ?? {};
	}

	set token(val) {
		if (this.data == null) {
			this.data = {};
		}
		this.data = { ...this.data,token: val };
	}

	get token() {
		return this.data?.token;
	}
}

const localStorageService = new LocalStorageService();
const indexDbService = new IndexDBService();

export { localStorageService,indexDbService };
