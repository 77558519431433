<template>
  <div class="file-upload-container">
    <div class="file-display-container">
      <ItemDisplay v-if="value && Array.isArray(value) != true" :itemData="value.data"
        :itemType="getItemDataType(value.mimetype)" :itemName="value.filename" :details="value.filesize"></ItemDisplay>
      <div v-if="value && Array.isArray(value) == true" class="file-display-gallery">
        <ItemDisplay v-for="item in value" :key="item.filename" :itemData="value.data"
          :itemType="getItemDataType(value.mimetype)" :itemName="value.filename" :details="value.filesize"></ItemDisplay>
      </div>
    </div>
    <label :for="'fileuploader' + cmpId" class="drop-container" :class="{ 'drag-active': dragActive }"
      @dragover="dropContainerDragOver" @dragleave="dropContainerDragLeave" @dragenter="dropContainerDragEnter"
      @drop="dropContainerDrop">
      <input ref="fileInput" :id="'fileuploader' + cmpId" type="file"
        @change="($event) => successUpload(field.key,$event)" :accept="field.accept ?? '*'"
        :multiple="field.multiple ?? false" capture />
    </label>

  </div>
</template>

<script>
import { getItemDataType } from '../../helpers/common';
import ItemDisplay from '../ItemDisplay.vue';

export default {
  name: "CustomFileUploadCmp",
  components: { ItemDisplay },
  props: {
    modelValue: { type: String,default: () => undefined },
    field: { type: Object,default: () => undefined },
    disabled: { type: Boolean,default: () => false },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: undefined,
      cmpId: undefined,
      dragActive: false,
    };
  },
  watch: {
    modelValue(val) {
      this.value = val;
    },
    value() {
      this.$emit('update:modelValue',this.value);
    }
  },
  computed: {
    fileInput() {
      return this.$refs['fileInput'];
    }
  },
  mounted() {
    this.cmpId = this.$.uid
  },
  methods: {
    dropContainerDragOver(e) {
      e.preventDefault()
    },
    dropContainerDragEnter() {
      this.dragActive = true;
    },
    dropContainerDragLeave() {
      this.dragActive = false;
    },
    dropContainerDrop(e) {
      e.preventDefault()
      this.dragActive = false;

      if (this.fileInput && e?.dataTransfer?.files) {
        this.fileInput.files = e.dataTransfer.files
      }
    },
    getItemDataType(data) {
      return getItemDataType(data)
    },
    async successUpload(key,data) {
      data.target.disabled = true;
      if (data?.target?.files?.length > 0) {
        await Promise.all([...(data?.target?.files ?? [])]
          .map((x,index) => new Promise((res) => {
            const reader = new FileReader();
            reader.onload = (event) => {
              const field = this.field;
              field.dataFormatter = field.dataFormatter ?? function (file,data) {
                return data;
              };
              if (data.target.files.length == 1) {
                this.value = field.dataFormatter(x,event.target.result);
              }
              else {
                this.value = this.value ?? [];
                this.value.push(field.dataFormatter(x,event.target.result));
              }

              res(true);
            };
            reader.readAsDataURL(x);
          }))).catch((e) => null);
      }
      data.target.disabled = false;
    },
  },
};
</script>
<style lang="scss" scoped>
input[type=file] {
  width: 20rem;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: var(--message-bgcolor);
  border-radius: 1rem;
  border: 1px solid #555;
}

input[type=file]::file-selector-button {
  border: none;
  background: var(--info-color);
  color: var(--message-bgcolor);
  cursor: pointer;
  transition: background .2s ease-in-out;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin: 0.2rem .2rem;
  padding: 0.5rem;
  padding-left: 0.55rem;

  border-radius: 0.5rem;
  border-width: 0;
  font-weight: 500;

  box-shadow: 0 2px 5px 0 rgba(var(--shadow-rgb), 0.26),
    0 2px 10px 0 rgba(var(--shadow-rgb), 0.16);
}

input[type=file]::file-selector-button:hover {
  background: var(--info-color);
}

.drop-container {
  position: relative;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10rem;
  padding: 2rem;
  border-radius: 1rem;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: var(--message-owned-bgcolor);
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}
</style>