import axios from "@/axios";
import { checkIdExist,storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/ig/comments',{
  actions: {
    submitReply({ dispatch },{ id,message,organisationId }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/ig/comments/${id}/reply`,
          params: {
            organisationId,
          },
          data: { message },
        })
        .then((d) => {
          return d.data;
        });
    },
    markAsRead({ dispatch },{ id }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "PATCH",
          url: `/api/ig/comments/${id}/markAsRead`,
          params: {},
        })
        .then((d) => {
          return d.data;
        });
    },
    getMentioned({ dispatch },{ id,organisationId,pageId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest(
          {
            url: `/api/ig/comments/${id}/mentioned`,
            params: {
              organisationId,
              pageId,
              force,
            },
          },
          !(force == true)
        )
        .then((d) => {
          return d.data;
        });
    },
    getCustomers({ dispatch },{ id,organisationId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/ig/comments/${id}/customers`,
          params: { organisationId,force }
        })
        .then((d) => {
          return d.data;
        });
    },
    addCustomer({ dispatch },{ id,organisationId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: 'POST',
          url: `/api/ig/comments/${id}/customers`,
          data: { organisationId },
          params: { force }
        })
        .then((d) => {
          return d.data;
        });
    },

  },
  cacheKey: "FBIGCOMMENT"
});

export default module;
