import axios from "@/axios";
import { checkIdExist, storeDefaultModule } from "../common";

const module = storeDefaultModule('/api/notifications',{
  state: {
    unreadCount: 0,
  },
  mutations: {
    updateUnreadCount(state,val) {
      state.unreadCount = val;
    },
  },
  actions: {
    markAllRead({ dispatch },{ params }) {
      return axios
        .baseRequest({
          method: "PATCH",
          url: `/api/notifications/markAllRead`,
          data: params,
        })
        .then((d) => {
          dispatch("unreadCount");
          return d.data;
        });
    },
    markAsRead({ dispatch },{ id }) {
      checkIdExist(id,{ dispatch });
    
      return axios
        .baseRequest({
          method: "PATCH",
          url: `/api/notifications/${id}/markAsRead`,
        })
        .then((d) => {
          dispatch("unreadCount");
          return d.data;
        });
    },
    unreadCount({ commit }) {
      return axios
        .baseRequest(
          {
            url: `/api/notifications/unreadCount`,
            notifyStatus: false,
          },
          false
        )
        .then((d) => {
          commit("updateUnreadCount",d.data.count);
          return d.data;
        });
    },
    getTypes(a,{ userId } = {}) {
      return axios
        .baseRequest(
          {
            url: `/api/notifications/types`,
            params: { userId },
            notifyStatus: false,
          },
          false
        )
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "NOTIFICATION"
});

export default module;
