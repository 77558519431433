<template>
  <select v-if="optionsValues" :value="modelValue" :disabled="disabled"
    @input="$emit('update:modelValue',$event.target.value)">
    <option v-for="option in optionsValues" :key="option.val" :value="option.val"> {{ option.label }}
    </option>
  </select>
</template>

<script>
export default {
  name: "OrganisationQuickReplySelectorCmp",
  components: {},
  props: {
    organisationIds: { type: Array,default: () => undefined },
    channelIds: { type: Array,default: () => undefined },
    modelValue: { type: String,default: () => "" },
    disabled: { type: Boolean,default: () => false },
    availableIds: { type: Array },
  },
  emits: ['update:modelValue'],
  watch: {
    channelIds(){
      this.updateOptions();
    },
    organisationIds(){
      this.updateOptions();
    }
  },
  data() {
    return {
      optionsValues: undefined
    };
  },
  mounted() {
    this.updateOptions();
  },
  methods: {
    updateOptions() {
      const filter = {};
      if (this.organisationIds && this.organisationIds.length > 0) {
        filter.orgOrChQuery =  filter.orgOrChQuery  ?? {};
        filter.orgOrChQuery.organisationIds = this.organisationIds;
      }
      if (this.channelIds && this.channelIds.length > 0) {
        filter.orgOrChQuery =  filter.orgOrChQuery  ?? {};
        filter.orgOrChQuery.channelIds = this.channelIds;
      }
      return this.$store.dispatch("organisationQuickReply/getAll",{
        filter,
        options: { sort: { name: 1 },projection: { _id: 1,name: 1 } },
      }).then(d => {
        if (d.data && d.data.length > 0) {
          this.optionsValues = d.data.map(x => {
            return { label: x.name,val: x._id };
          })
        } else {
          this.optionsValues = [];
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped></style>