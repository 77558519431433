<template>
  <div class="main-route-container" :class="{ offline: backendError,'single-page': singlePage }"
    :style="{ visibility: isReady ? 'visible' : 'hidden' }" v-if="isReady" @click="(e) => itemClicked(e)">
    <router-view></router-view>
    <notifications></notifications>
  </div>
</template>

<script>
import "@/helpers/socketService";
import socket from "@/helpers/socketService";
import i18nService from "./controllers/i18nService";

export default {
  name: "App",
  components: {},
  watch: {
    "$store.state.notificationData": {
      handler: function (data) {
        if (Array.isArray(data)) {
          data.map((x) => {
            this.$store.dispatch("notificationDisplayed",x);
            this.$notify(x);
          });
        } else {
          this.$notify(data);
        }
      },
      deep: true,
    },
    "$store.state.singlePage"(val) {
      this.singlePage = val;
    },
    "$store.state.auth.user"(val) {
      if (val == null) {
        socket.disconnect();
      } else {
        socket.connect();
        this.updateNotificationCount();
        i18nService.loadLanguageAsync(val.language,false);
      }
    },
    "$store.state.lastUpdateNotification"(val) {
      if (val && val.objectType.match(/^NOTIFICATION/) && val.data) {
        this.updateNotificationCount();
      }
    },
  },
  computed: {
    backendError() {
      return this.$store.state.backendStatus != true;
    },
  },
  data() {
    return { isReady: false,singlePage: false };
  },
  mounted() {
    this.singlePage = this.$store.state.singlePage;
    this.$store
      .dispatch("auth/status",{ notifyStatus: false })
      .catch(() => {
        return;
      })
      .then(() => {
        this.isReady = true;
        this.updateNotificationCount();
      });
  },
  unmounted() {
  },
  methods: {
    updateNotificationCount() {
      return this.$store.dispatch("notification/unreadCount").catch((e) => { });
    },
    itemClicked(e) {
      this.$store.dispatch("logger/log",{
        data: {
          name: "ItemClicked",
          event: { name: e?.constructor?.name,target: e?.target?.outerHTML },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-route-container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  &::v-deep(.statistic-item) {
    flex: 1;
    display: flex;
    color: gray;


    &.tooltip-container .tooltip-face {
      display: flex;
      flex-direction: row;
      font-size: 0.9rem;
    }

    &>* {
      margin: 0.2rem;
    }

    .fa-* {
      font-size: 0.9rem;
    }
  }
}
</style>

<style lang="scss">
body {
  --content-bgcolor: 255, 255, 255;
  --input-background-color: 240, 242, 245;
  --input-color: 17, 27, 33;
  --input-light-color: 255, 255, 255;
  --border-color: 56, 53, 60, 0.33;
  --header-bgcolor: #f0f2f5;
  --message-bgcolor: #fff;
  --message-owned-bgcolor: #d9fdd3;
  --navbutton-padding: 0.6rem;
  --background-color: #f4f6f8;
  --shadow-rgb: 11, 20, 26;

  --info-color: blue;
  --primary-color: red;

  font-family: "Open Sans", sans-serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  color: #2c3e50;
  width: 100%;
  display: flex;
  flex-direction: row;

  .main-route-container.offline>* {
    filter: grayscale(100%);
  }
}

.avatar-data-container {
  display: flex;
  flex-direction: column;
}

.actionButton-data-container,
.conversation-list-container {
  .buttons-container {
    display: flex;
  }

  .vs-button {
    margin: 0.1rem 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .vs-button-text {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .material-icons {
      font-size: 0.9rem;
    }
  }
}

.component-header {
  display: flex;
  flex-direction: row;

  .actions-container {
    margin-left: auto;
    display: inline-flex;
  }
}

.main-route-container {
  &.single-page {
    flex: 1 1 auto;
    max-height: 100vh;

    .base-route-container {
      flex: 1 1 auto;
      overflow: auto;

      .auth-route-container {
        flex: 1 1 auto;
        overflow: auto;
      }
    }
  }
}

.custom-data-display .item-data {
  display: flex;
  flex-direction: column;

  .value-container {
    padding-left: 1rem;
    max-width: 100%;
    overflow: hidden;
    word-wrap: break-word;

    &>.item-data {

      label,
      .value-container {
        font-size: 0.9rem;
      }
    }
  }
}

label {
  font-size: 1.1rem;
  font-weight: bold;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.vs-list--slot {
  margin-left: inherit;
  flex: 1;
}

.vs-dropdown-menu {
  min-width: 10rem;

  .vs-dropdown--menu {
    --dropdown: #fff;
    --shadow-rgb: 11, 20, 26;
    --listItem: #3b4a54;
    --vs-primary: #3b4a54;
    --listItemHover: #f5f6f6;

    display: block;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    background: var(--dropdown);
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(var(--shadow-rgb), 0.26),
      0 2px 10px 0 rgba(var(--shadow-rgb), 0.16);
    padding: 0.6rem 0;
    position: relative;
    max-width: 9.5rem;

    .vs-dropdown--item {
      position: relative;
      padding-right: .5rem;
      padding-left: .5rem;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      color: var(--listItem);
      font-size: 0.9rem;
      cursor: pointer;
      box-sizing: border-box;
      display: inline-block;
      height: 2rem;

      &:hover {
        background: var(--listItemHover);
        color: inherit !important;
      }
    }

    .vs-dropdown--menu--after {
      display: none;
    }
  }
}

.data-image-urls {
  max-width: 20rem;
}

.numb {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  margin-right: 0.4rem;
  padding: .2rem .3rem;

  border-radius: .5rem;
  min-width: 1.2rem;
  min-height: 1.2rem;

  background: var(--info-color);
  color: #fff;

  font-weight: 500;
  font-size: 0.8rem;
}

.passive-label {
  font-size: 0.7rem;
  margin: 0.2rem 0.5rem;
  color: white;
  background-color: gray;
  padding: 0.2rem;
  border-radius: 0.5rem;
}
</style>
