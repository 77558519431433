import axios from "../../../axios";
import { checkIdExist, storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/wa/waba',{
  actions: {
    getTemplates({ dispatch },{ id,organisationId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/wa/waba/${id}/templates`,
          params: { organisationId,force }
        })
        .then((d) => {
          return d.data;
        });
    },
    getSubscriptions({ dispatch },{ id,organisationId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/wa/waba/${id}/subscriptions`,
          params: { organisationId,force }
        })
        .then((d) => {
          return d.data;
        });
    },
    getCategories({ dispatch },{ id,organisationId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/wa/waba/${id}/categories`,
          params: { organisationId,force }
        })
        .then((d) => {
          return d.data;
        });
    },
    getPhones({ dispatch },{ id,organisationId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/wa/waba/${id}/phones`,
          params: { organisationId,force }
        })
        .then((d) => {
          return d.data;
        });
    },
    getAudience({ dispatch },{ id,organisationId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/wa/waba/${id}/audience`,
          params: { organisationId,force }
        })
        .then((d) => {
          return d.data;
        });
      }
  },
  cacheKey: "FBWABA"
});

export default module;
