import axios from "../../../axios";
import { checkIdExist, storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/waweb/phones',{
  actions: {
    getLabels(
      { dispatch },{
        id,
        organisationId,
        filter,
        force
      }) {
        checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "GET",
          url: `/api/waweb/phones/${id}/labels`,
          params: {
            organisationId,
            force,
            filter
          },
        })
        .then((d) => d.data);
    },
    getLabelChats({ dispatch },{
      id,
      labelId,
      organisationId,
      filter,
      force
    }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "GET",
          url: `/api/waweb/phones/${id}/labels/${labelId}/chats`,
          params: {
            organisationId,
            force,
            filter
          },
        })
        .then((d) => d.data);
    },
  },
  cacheKey: "WAWEBPHONE"
});

export default module;