import axios from "../../../axios";
import { checkIdExist, storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/waweb/chats',{
  actions: {
    getLabels({ dispatch },{
      id,
      organisationId,
      waWebPhoneId,
      force,
    }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/waweb/chats/${id}/labels`,
          params: {
            id,
            organisationId,
            waWebPhoneId,
            force,
          },
        })
        .then((d) => d.data);
    },
    getCustomers({ dispatch },{ id,organisationId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/waweb/chats/${id}/customers`,
          params: { organisationId,force }
        })
        .then((d) => {
          return d.data;
        });
    },
    addCustomer({ dispatch },{ id,organisationId }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: 'POST',
          url: `/api/waweb/chats/${id}/customers`,
          data: { organisationId }
        })
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "WAWEBCHAT"
});

export default module;