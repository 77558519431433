import axios from "../../axios";
import { checkIdExist, storeDefaultModule } from "../common";

const module = storeDefaultModule('/api/organisations/memberships',{
  actions: {
    getMembers({dispatch},organisationId) {
      checkIdExist(organisationId,{ dispatch });

      return axios
        .baseRequest({
          url: `/api/organisations/memberships/organisation/${organisationId}/members/users`,
        })
        .then((d) => {
          return d.data;
        });
    },
    addMember({ dispatch },{ organisationId,data }) {
      checkIdExist(data?.userId,{ dispatch });
      checkIdExist(organisationId,{ dispatch });

      return axios
        .baseRequest({
          method: "POST",
          url: `/api/organisations/memberships/organisation/${organisationId}/members`,
          data,
        })
        .then((d) => {
          return d.data;
        });
    },
    getMemberById({ dispatch },{ organisationId,id,notifyStatus = true }) {
      checkIdExist(id,{ dispatch });
      checkIdExist(organisationId,{ dispatch });

      return axios
        .baseRequest({
          url: `/api/organisations/memberships/organisation/${organisationId}/members/user/${id}`,
          notifyStatus
        })
        .then((d) => {
          return d.data;
        });
    },
    updateMember({ dispatch },{ organisationId,userId,data }) {
      checkIdExist(userId,{ dispatch });
      checkIdExist(organisationId,{ dispatch });

      return axios
        .baseRequest({
          method: "POST",
          url: `/api/organisations/memberships/organisation/${organisationId}/members/user/${userId}`,
          data: { $set: data },
        })
        .then((d) => {
          return d.data;
        });
    },
    getNonMembers({ dispatch },{ organisationId }) {
      checkIdExist(organisationId,{ dispatch });

      return axios
        .baseRequest({
          url: `/api/organisations/memberships/organisation/${organisationId}/nonmembers/users`,
        })
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "ORGANISATIONMEMBERSHIP"
});

const clearCachedDataSuper = module.actions.clearCachedData;
module.actions.clearCachedData = ({ dispatch },{ item,key }) => {
  return clearCachedDataSuper({ dispatch },{ item,key });
}

export default module;