<template>
  <div class="sort-component-container" v-if="sortDisplay && sortDisplay.length > 0">
    <label>{{ $t("general.sortBy") }}</label>
    <div class="sort-button-container">
      <CustomButton v-for="option in sortDisplay" :key="option.key"
         :buttonClass="{ 'sort-option': true, selected: option.sort != 0,['selection-order' + getIndexOfImportance(option)]: getIndexOfImportance(option) != -1,}"
        @click="changeSort(option)" :disabled="disabled">
        <span>{{ option.text }}</span>
        <i v-if="option.sort == -1" class="fa-solid fa-caret-up"></i>
        <i v-if="option.sort == 1" class="fa-solid fa-caret-down"></i>
      </CustomButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomSortCmp",
  props: {
    sortData: { type: Object,default: () => { } },
    sortOptions: { type: Array,default: () => [] },
    disabled: { type: Boolean,default: () => false },
  },
  data() {
    return {
      sortDisplay: undefined,
      sortDisplayData: undefined,
    };
  },
  watch: {
    sortOptions() {
      this.generateOptions();
    },
    sortData(val) {
      this.sortDisplayData = { ...val };
    },
    sortDisplayData(val) {
      this.generateOptions();
    },
  },
  mounted() {
    this.sortDisplayData = { ...(this.sortData ?? {}) };
    this.generateOptions();
  },
  computed: {},
  emits: ["sortChanged"],
  methods: {
    getIndexOfImportance(option) {
      const sortDataKeys = Object.keys(this.sortDisplayData);
      return sortDataKeys.indexOf(option.key);
    },
    generateOptions() {
      if (this.sortOptions?.length > 0) {
        if (this.sortDisplayData) {
          this.sortDisplay = this.sortOptions.map((x) => {
            return { ...x,sort: this.sortDisplayData[x.key] ?? 0 };
          });
        } else {
          this.sortDisplay = this.sortOptions.map((x) => {
            return { ...x,sort: 0 };
          });
        }
      }
    },
    changeSort(option) {
      this.sortDisplayData[option.key] = this.sortDisplayData[option.key] ?? 0;
      this.sortDisplayData[option.key] += 2;
      this.sortDisplayData[option.key] %= 3;
      this.sortDisplayData[option.key] -= 1;

      if (this.sortDisplayData[option.key] == 0) {
        delete this.sortDisplayData[option.key];
      }
      this.$emit("sortChanged",this.sortDisplayData);
    },
  },
};
</script>

<style lang="scss" scoped>
.sort-component-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  label {
    font-size: 0.7rem;
  }

  .sort-button-container {
    padding: .1rem .3rem;
    display: flex;
    flex-wrap: wrap;

    :deep(button) {
      box-shadow: none;
      &.sort-option{
      border: none;
      min-width: 2rem;
      padding: 0.2rem 0.4rem;
      color: blue;
      background-color: rgba($color: lightgrey, $alpha: 0.7);
      border-radius: 0.5rem;
      margin: 0.1rem;
      font-size: .8rem;


      i{
        font-size: 1rem;
      }

      &.selected {
        background-color: rgba($color: blue, $alpha: 0.4);
        color: white;

        i {
          padding-left: 0.2rem;
        }

        &.selection-order0 {
          background-color: rgba($color: blue, $alpha: 0.7);
        }

        &.selection-order1 {
          background-color: rgba($color: blue, $alpha: 0.55);
        }
      }

      &:disabled {
        color: gray;
        cursor: not-allowed;
      }
    }
    }
  }
}
</style>