import axios from "axios";
import moment from "moment";
import { createI18n } from "vue-i18n";
import { store } from "../store";

export const interfaceAvailableLang = ["en","tr"];

export const Languages = {
  English: "EN",
  Turkish: "TR",
};

export const languageNativeNames = {
  [Languages.English]: "English",
  [Languages.Turkish]: "Türkçe",
};

export const preferredCountries = {
  Turkey: "TR",
  USA: "US",
  Germany: "DE",
  France: "FR",
};

export const defaultLanguage = Languages.Turkish;

class I18nService {
  constructor() {
    const lang = "tr";
    this.moment = moment;
    this.loadedLanguages = [];
    this.i18n = createI18n({
      locale: lang,
      fallbackLocale: defaultLanguage,
      messages: {},
      silentTranslationWarn: true,
    });
    this.loadLanguageAsync(lang,true);
  }

  setI18nLanguage(lang) {
    moment.locale(lang);
    this.i18n.global.locale = lang;
    this.i18n.locale = lang;
    axios.defaults.headers.common["Accept-Language"] = lang;
    const htmlElement = document.querySelector("html");
    if (htmlElement) {
      htmlElement.setAttribute("lang",lang);
    }
    return lang;
  }

  formatAndValidateLang(val) {
    if (val && typeof val == "string") {
      val = val.trim().toLowerCase();
      if (interfaceAvailableLang.indexOf(val) != -1) {
        return val;
      }
    }
    throw Error("NOT_VALID_LANGUAGE " + JSON.stringify(val));
  }
  async loadLanguageAsync(lang,initial = false) {
    lang = this.formatAndValidateLang(lang);
    if (this.i18n.locale === lang && !initial) {
      return Promise.resolve(this.setI18nLanguage(lang));
    }

    if (this.loadedLanguages.includes(lang)) {
      return Promise.resolve(this.setI18nLanguage(lang));
    }

    return import(`@/locale/${lang}.json`)
      .then((messages) => {
        this.i18n.global.setLocaleMessage(lang,messages.default);
        this.loadedLanguages.push(lang);
        store.commit("appi18nChecked",{});
        return this.setI18nLanguage(lang);
      });
  }
}
const i18nService = new I18nService();
export default i18nService;
