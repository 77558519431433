import axios,{ clearCacheByUrlPart } from "../axios";
import { IndexDBService,indexDbService } from "../controllers/localStorageService";
import i18nService from "../controllers/i18nService"

export function displayWarningMessage(title,{ dispatch }) {
    dispatch(
        "showNotification",
        {
            type: "warn",
            title: title
        },
        { root: true }
    );
}

export const checkIdExist = function (id,{ dispatch,errorMsg }) {
    if (!((id?.length ?? 0) > 0)) {
        displayWarningMessage(i18nService.i18n.global.t(errorMsg ?? 'messages.ID_NOT_DEFINED'),{ dispatch });
        throw new Error();
    }
    return true;
}

export function checkDataExist(data,{ dispatch,errorMsg }) {
    if (data == null || Object.keys(data).length == 0) {
        displayWarningMessage(i18nService.i18n.global.t(errorMsg ?? 'messages.DATA_EMPTY'),{ dispatch })
        throw new Error();
    }
    return true;
}

function checkDataId(data,{ dispatch }) {
    if (data != null && data.id != null) {
        displayWarningMessage(i18nService.i18n.global.t('messages.DATA_CONTAINS_ID'),{ dispatch })
        throw new Error();
    }
    return true;
}

export function storeDefaultModule(baseUrl = "",options) {
    return {
        namespaced: "true",
        state: {
            ...(options?.state ?? {})
        },
        getters: {
            ...(options?.getters ?? {})
        },
        mutations: {
            ...(options?.mutations ?? {})
        },
        modules: {
            ...(options?.modules ?? {})
        },
        actions: {
            ...storeDefaultActions(baseUrl,options),
            ...(options?.actions ?? {})
        },
    }
}

export function storeDefaultActions(baseUrl = "",options) {
    const storeGetByIdReqQueue = {};

    /// Avatar display push too many individual request - merge them to request less request
    setInterval(() => {
        return Object.keys(storeGetByIdReqQueue).map(async key => {
            if (storeGetByIdReqQueue[key].length > 0) {
                let reqList = storeGetByIdReqQueue[key];
                delete storeGetByIdReqQueue[key];

                const params = JSON.parse(key);
                await actions.getAll(undefined,{
                    ...params,
                    filter: {
                        _id: {
                            $in: reqList
                                .filter(x => x != null && x.id != null && x.id.length > 0)
                                .reduce((p,c) => { if (p.findIndex(x => x == c.id) == -1) { p.push(c.id); } return p },[])
                                .sort()
                        }
                    },
                    limit: reqList.length,
                    skip: 0
                }).then((data) => {
                    if (data?.data && Array.isArray(data.data)) {
                        data.data.forEach(val => {
                            reqList
                                .filter(x => x.id == val._id)
                                .map((x) => {
                                    x.callback(null,val);
                                    return;
                                });
                            reqList = reqList
                                .filter(x => x.id != val._id);
                        });
                    }
                    reqList.forEach((x) => x.callback("NOT_FOUND",{}));
                    return data.data;
                });
            }
        })
    },300);
    
    const actions = {
        getAll(a,params = {}) {
            try {
                const reqOptions = params.options;
                delete params.options;
                if (params.filter) {
                    if (Object.keys(params.filter).length == 0) {
                        delete params.filter;
                    }
                }
                return axios
                    .baseRequest(
                        {
                            url: baseUrl,
                            params: {
                                ...params,
                                ...reqOptions,
                            },
                        },
                        !(reqOptions?.force == true)
                    )
                    .then(async (d) => {
                        if (d.data && d.data.data && Array.isArray(d.data.data)) {
                            if (options?.cacheKey && IndexDBService.DB_STORE_NAMES[options.cacheKey] != null) {
                                await Promise.all(d.data.data.map(x => {
                                    const key = JSON.stringify({
                                        organisationId: (params?.organisationId ?? {}),
                                        projection: (reqOptions?.projection ?? {})
                                    });
                                    return indexDbService.addOrUpdate(options.cacheKey,x,key)
                                        .catch(() => null);
                                })).catch(() => null);
                            }
                        }
                        return d.data;
                    });
            } catch (e) {
                return;
            }
        },
        async getById({ dispatch },{ id,organisationId,projection,force,mergeRequest,quickReturn }) {
            try {
                checkIdExist(id,{ dispatch });
                const key = JSON.stringify({ organisationId: (organisationId ?? {}),projection: (projection ?? {}) });
                if (options?.cacheKey && IndexDBService.DB_STORE_NAMES[options.cacheKey] != null && force != true) {
                    const data = await indexDbService.get(options.cacheKey,id,key)
                        .catch(() => null);
                    if (data) {
                        return data;
                    }
                }

                if (force != true && mergeRequest == true) {
                    return new Promise((res) => {
                        storeGetByIdReqQueue[key] = storeGetByIdReqQueue[key] ?? [];
                        storeGetByIdReqQueue[key].push({
                            id,
                            callback: (e,d) => {
                                if (e) {
                                    res(null);
                                } else {
                                    res(d);
                                }
                            }
                        });
                    });
                }

                return axios
                    .baseRequest({
                        url: `${baseUrl}/${id}`,
                        params: { organisationId,projection,force,quickReturn }
                    },!(force == true))
                    .then(async (d) => {
                        if (options?.cacheKey && IndexDBService.DB_STORE_NAMES[options.cacheKey] != null) {
                            await indexDbService.addOrUpdate(options.cacheKey,d.data,key)
                                .catch((e) => null);
                        }
                        return d.data;
                    });
            } catch (e) {
                return null;
            }
        },
        add({ dispatch },data) {
            if (options?.allowAdd == false) {
                displayWarningMessage(i18nService.i18n.global.t('messages.NOT_ALLOW_TO_ADD'),{ dispatch })
                throw new Error();
            }
            checkDataId(data,{ dispatch });
            checkDataExist(data,{ dispatch });
            return axios
                .baseRequest({
                    method: "POST",
                    url: baseUrl,
                    data,
                })
                .then(async (d) => {
                    if (options?.cacheKey && IndexDBService.DB_STORE_NAMES[options.cacheKey] != null) {
                        dispatch('clearCachedData',{ item: d.data,key: options?.cacheKey + "_ADD" });
                    }
                    return d.data;
                });
        },
        update({ dispatch },{ id,data,details }) {
            if (options?.allowUpdate == false) {
                displayWarningMessage(i18nService.i18n.global.t('messages.NOT_ALLOW_TO_UPDATE'),{ dispatch })
                throw new Error();
            }
            checkIdExist(id,{ dispatch });
            return axios
                .baseRequest({
                    method: "POST",
                    url: `${baseUrl}/${id}`,
                    data: {
                        $set: (data ?? {}),
                        ...(details ?? {})
                    },
                })
                .then(async (d) => {
                    if (options?.cacheKey && IndexDBService.DB_STORE_NAMES[options.cacheKey] != null) {
                        dispatch('clearCachedData',{ item: d.data,key: options?.cacheKey + "_UPDATED" });
                    }
                    return d.data;
                });
        },
        delete({ dispatch },{ id }) {
            if (options?.allowDelete == false) {
                displayWarningMessage(i18nService.i18n.global.t('messages.NOT_ALLOW_TO_DELETE'),{ dispatch })
                throw new Error();
            }
            checkIdExist(id,{ dispatch });
            return axios
                .baseRequest({
                    method: "DELETE",
                    url: `${baseUrl}/${id}`,
                })
                .then(async (d) => {
                    if (options?.cacheKey && IndexDBService.DB_STORE_NAMES[options.cacheKey] != null) {
                        dispatch('clearCachedData',{ item: d.data,key: options?.cacheKey + "_DELETED" });
                    }
                    return d.data;
                });
        },
        mergeList({ dispatch },{
            mergeQuery,
            matchQuery,
            applyMerge
        }) {
            if (options?.allowUpdate == false) {
                displayWarningMessage(i18nService.i18n.global.t('messages.NOT_ALLOW_TO_UPDATE'),{ dispatch })
                throw new Error();
            }
            return axios
                .baseRequest({
                    method: "POST",
                    url: `${baseUrl}/merge`,
                    data: {
                        mergeQuery,
                        matchQuery,
                        applyMerge
                    },
                })
                .then(async (d) => {
                    if (options?.cacheKey && IndexDBService.DB_STORE_NAMES[options.cacheKey] != null) {
                        dispatch('clearCachedData',{ item: d.data,key: options?.cacheKey + "_UPDATED" });
                    }
                    return d.data;
                });
        },
        async clearCachedData({ dispatch },{ item,key }) {
            clearCacheByUrlPart(new RegExp(baseUrl,"gi"));
            if (options?.cacheKey && IndexDBService.DB_STORE_NAMES[options.cacheKey] != null) {
                await indexDbService.delete(options.cacheKey,item?._id ?? item?.id)
                    .catch(() => {
                        //
                    });
            }
            if (key) {
                dispatch("objectUpdated",{
                    objectType: key,
                    data: [item]
                },{ root: true });
            }
            return;
        }
    }

    return actions;
}