
<template>
  <select v-if="optionsValues" :value="modelValue" :disabled="disabled"
    @input="$emit('update:modelValue',$event.target.value)">
    <option v-for="option in optionsValues" :key="option.val" :value="option.val" :selected="modelValue == option.val">
      {{ option.label }}
    </option>
  </select>
</template>

<script>
export default {
  name: "FbWaPhoneSelectorComponent",
  components: {},
  props: {
    organisationId: { type: String },
    waBAId: { type: String },

    defaultFilter: { type: Object },
    modelValue: { type: String,default: () => "" },
    disabled: { type: Boolean,default: () => false },
    availableIds: { type: Array },
    selectIfOneItem: { type: Boolean,default: () => false },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      optionsValues: undefined
    };
  },
  mounted() {
    this.updateOptions();
  },
  methods: {
    updateOptions() {
      return this.$store.dispatch("fbWaPhone/getAll",{
        organisationId: this.organisationId,
        waBAId: this.waBAId,
        filter: this.defaultFilter,
        options: {
          sort: { verifiedName: 1 },
          projection: { _id: 1,verifiedName: 1,displayPhoneNumber: 1 }
        },
      }).then(d => {
        if (d.data && d.data.length > 0) {
          this.optionsValues = d.data.map(x => {
            return { label: `${x.verifiedName}${x.displayPhoneNumber ? ' (' + x.displayPhoneNumber + ')' : ''}`,val: x._id };
          })

          if (this.selectIfOneItem == true &&
            (this.modelValue == null || this.modelValue == '') &&
            this.optionsValues?.length == 1) {
            this.$emit('update:modelValue',this.optionsValues[0].val);
          }
        } else {
          this.optionsValues = [];
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped></style>