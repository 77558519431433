
<template>
  <div v-if="data">
    <div v-if="displayType == 'mini'" class="mini-label" :style="'background-color:' + data?.hexColor"
      :class="{ 'showLightIcon': !showDark }" @click="manageClick">
      {{ data.name }}
      <span class="remove-selector" @click.stop="removeItem()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <CustomAvatar v-else class="waweb-label-avatar" :readyToDisplay="data != null" :dataIdentifier="id"
      :actionMenuOptions="menuOptions" @actionMenuClick="clickMenuAction" @itemClick="manageClick"
      @refreshClick="getLabel(true)">
      <template v-slot:defaultImage>
        <span class="label-icon" :style="'background-color:' + data?.hexColor" :class="{ 'showLightIcon': !showDark }">
          <i class="fa-solid fa-tag"></i>
        </span>
      </template>
      <template v-slot:mainData>
        {{ data.name }}
      </template>
      <template v-slot:dataDetails>
      </template>
      <template v-if="showInfo" v-slot:popupData="">
        <WaWebLabelDetails :initialData="data"></WaWebLabelDetails>
      </template>
    </CustomAvatar>

    <CustomPopup ref="labelDataPopup" :title="$t('general.label')" :showButton="false">
      <WaWebLabelAvatar :id="id" :waWebPhoneId="waWebPhoneId" :showInfo="false" :clickEnabled="false"></WaWebLabelAvatar>
      <WaWebLabelDetails :initialData="data"></WaWebLabelDetails>
    </CustomPopup>
    <ConfirmationDialog ref="deleteDataPopup" dialogType="confirm" dialogColor="danger"
      @confirmed="(e) => removeItemConfirmed(e)" :displayInitial="false"></ConfirmationDialog>
  </div>
</template>

<script>
import { hexToRgb,isColorDark } from "@/helpers/common";
import WaWebLabelDetails from "./WaWebLabelDetails.vue";

export default {
  name: "WaWebLabelAvatar",
  components: { WaWebLabelDetails },
  props: {
    id: { type: String },
    waWebChatId: { type: String },
    waWebPhoneId: { type: String },
    initialData: { type: Object },
    showInfo: { type: Boolean,default: () => true },
    displayType: { type: String },
    showInPopup: { type: Boolean,default: () => true },
    clickEnabled: { type: Boolean,default: () => true },
  },
  data() {
    return {
      data: undefined,
      menuOptions: [],
      rgbColor: undefined,
      showDark: false,
    };
  },
  computed: {
    deleteDataPopup() {
      return this.$refs['deleteDataPopup'];
    }
  },
  watch: {
    id() {
      this.getLabel();
    },
    initialData() {
      this.getLabel();
    },
    data() {
      if (this.data) {
        this.rgbColor = hexToRgb(this.data?.hexColor);
        if (this.rgbColor) {
          this.showDark = isColorDark(this.rgbColor.r,this.rgbColor.b,this.rgbColor.b);
        }
        else {
          this.showDark = false;
        }
      }
    }
  },
  mounted() {
    this.getLabel();
  },
  methods: {
    removeItem() {
      if (this.deleteDataPopup.displayDialog) {
        this.deleteDataPopup.displayDialog();
      }
    },
    removeItemConfirmed(e) {
      if (this.id && this.waWebChatId) {
        return this.$store.dispatch("waWebLabel/removeFromChat",{ id: this.id,waWebChatId: this.waWebChatId });
      } else if (this.id) {
        return this.$store.dispatch("waWebLabel/delete",{ id: this.id });
      }
      return
    },
    manageClick() {
      if (this.clickEnabled) {
        if (this.showInPopup) {
          this.showPopupData();
        }
      }
    },
    showPopupData() {
      this.$refs.labelDataPopup?.showPopup();
    },
    getLabel(force = false) {
      if (force != true && this.initialData != null) {
        this.data = this.initialData;
        return;
      }
      this.$store
        .dispatch("waWebLabel/getById",{
          id: this.id,
          waWebPhoneId: this.waWebPhoneId,
          mergeRequest: true,
          force
        }).then(d => {
          this.data = d;
        });
    },
    navigateToInstaUser() {
    },
  },
};
</script>

<style lang="scss" scoped>
.label-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin-top: 2px;
  }
}

.waweb-label-avatar {
  display: inline-flex;
}

.mini-label {
  display: inline-flex;
  border-radius: .5rem;
  padding: .3rem .5rem;
  margin: .2rem .3rem;
  font-size: .7rem;
  font-weight: 600;
  cursor: pointer;
}

.showLightIcon {
  color: rgba($color: white, $alpha: .9);
}
</style>