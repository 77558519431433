<template>
  <div class="fb-comment-list-container">
    <FbPostAvatar
      v-if="showMainPost"
      class="main-post-container"
      :organisationId="organisationId"
      :pageId="pageId"
      :postId="postId"
      :showInCard="true"
      :showStatus="false"
    ></FbPostAvatar>

    <FbCommentAvatar
      class="parent-comment-container"
      v-if="parentId && showParentAvatar"
      :commentId="parentId"
      :organisationId="organisationId"
      :showInCard="true"
      :displaySmall="true"
      :showActionMenu="true"
      :showPage="false"
      :showPost="false"
    ></FbCommentAvatar>

    <CustomTable
      class="fb-comment-list"
      ref="listComponent"
      :hideHeader="hideHeader"
      :displayReverse="displayReverse"
      :paginateScroll="paginateScroll"
      :groupItems="groupByDate"
      :activeItem="activeItem"
      :itemGroupFunction="itemDateFunction"
      :headers="commentHeaders"
      :initialSort="initialSort"
      :sortOptions="sortOptions"
      :filterOptions="filterOptions"
      :defaultFilter="defaultFilter"
      :getDataRequest="getDataRequest"
      :lastUpdateWatchFunction="lastUpdateWatchFunction"
      @itemCountChanged="(e) => emitItemCount(e)"
      :hideIfEmpty="hideIfEmpty"
    >
      <template v-slot:avatar="{ data }">
        <div
          v-if="groupByDate && data && data.type == 'dayLabel'"
          class="date-box"
        >
          {{ data.data }}
        </div>
        <FbCommentAvatar
          v-if="!(groupByDate && data && data.type == 'dayLabel')"
          :commentId="data.key"
          :organisationId="organisationId"
          :showInCard="true"
          :showDetails="true"
          :showActionMenu="true"
          :navigateOnClick="navigateOnClick"
          :showPage="showPage"
          :showPost="false"
          :displaySimple="displaySimple"
          :showParentComment="
            showParentComment || (showParentAvatar != true && parentId == null)
          "
          :showShortDate="groupByDate"
          :showPageImage="showPageImage"
          @itemClick="emitClick(data.key)"
        ></FbCommentAvatar>
        <FbCommentList
          v-if="!(data && data.type == 'dayLabel') && itemShowChilds"
          class="child-list-container"
          :class="{ 'hidden-list': !(itemCount[data.key] > 0) }"
          :postId="data.source.fbPostId"
          :parentId="data.source._id"
          :showParentAvatar="false"
          :showMainPost="false"
          :hideHeader="true"
          :navigateOnClick="false"
          :displayReverse="true"
          :paginateScroll="true"
          :showActionColumn="false"
          :displaySimple="displaySimple"
          :itemShowChilds="itemShowChilds"
          :showPage="false"
          @itemCountChanged="(e) => childCountChanged(data.key, e)"
        ></FbCommentList>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import FbPostAvatar from "@/components/facebook/FbPostAvatar";

export default {
  name: "CommentListComponent",
  props: {
    organisationId: { type: String, default: () => null },
    postId: { type: String, default: () => null },
    pageId: { type: String, default: () => null },
    parentId: { type: String, default: () => null },
    fbUserId: { type: String, default: () => null },
    hideHeader: { type: Boolean, default: () => false },
    displayReverse: { type: Boolean, default: () => false },
    paginateScroll: { type: Boolean, default: () => false },
    groupByDate: { type: Boolean, default: () => false },
    displaySimple: { type: Boolean, default: () => false },
    showMainPost: { type: Boolean, default: () => false },
    showParentAvatar: { type: Boolean, default: () => false },
    showParentComment: { type: Boolean, default: () => false },
    navigateOnClick: { type: Boolean, default: () => false },
    activeItem: { type: String, default: () => null },
    onlyRootComments: { type: Boolean, default: () => false },
    onlyCustomerComments: { type: Boolean, default: () => false },
    showActionColumn: { type: Boolean, default: () => true },
    showPage: { type: Boolean, default: () => true },
    defaultFilter: { type: Object, default: () => undefined },
    filterOptions: { type: Array, default: () => [] },
    itemShowChilds: { type: Boolean, default: () => false },
    hideIfEmpty: { type: Boolean, default: () => false },
    showPageImage: { type: Boolean, default: () => false },
  },
  data() {
    return {
      itemDateFunction: (x) => x.createdTime,
      itemCount: {},
      initialSort: { createdTime: -1 },
      sortOptions: [
        {
          key: "agentId",
          text: this.$t("model.basic-agent"),
        },
        {
          key: "isRead",
          text: this.$t("model.basic-is_read"),
        },
        {
          key: "createdTime",
          text: this.$t("model.basic-date"),
        },
      ],
      getDataRequest: (tableData) => {
        tableData = tableData ?? {};
        tableData.filter = tableData?.filter ?? {};
        if (this.onlyRootComments) {
          tableData.filter.parentCommentId = { $exists: false };
        }
        if (this.onlyCustomerComments) {
          tableData.filter.ownerSent = { $ne: true };
        }
        return this.$store.dispatch("fbComment/getAll", {
          organisationId: this.organisationId,
          pageId: this.pageId,
          postId: this.postId,
          fbUserId: this.fbUserId,
          parentId: this.parentId,
          options: {
            ...tableData,
            projection: { _id: 1, createdTime: 1, fbPostId: 1 },
          },
        });
      },
      lastUpdateWatchFunction: (val) => {
        if (val && val.data && Array.isArray(val.data)) {
          if (
            this.postId &&
            val.objectType.match(/^FBPOST_UPDATED/) &&
            val.data.find((x) => x._id == this.postId)
          ) {
            return true;
          }
          if (
            this.parentId &&
            val.objectType.match(/^FBCOMMENT/) &&
            val.data.find((x) => x.parentCommentId == this.parentId)
          ) {
            return true;
          }
          if (
            this.postId &&
           ( val.objectType.match(/^FBCOMMENT_ADD/) ||  val.objectType.match(/^FBCOMMENT_DELETE/)) &&
            val.data.find(
              (x) =>
                x.fbPostId &&
                this.postId &&
                x.fbPostId == this.postId &&
                x.parentCommentId == this.parentId
            )
          ) {
            return true;
          }
        }
        return false;
      },
    };
  },
  watch: {
    postId() {
      this.refreshTable();
    },
    parentId() {
      this.refreshTable();
    },
  },
  computed: {
    commentHeaders() {
      const array = [{ key: "avatar", data: "avatar" }];
      if (this.showActionColumn && this.canManage) {
        array.push({ key: "actionButton", data: "" });
      }
      return array;
    },
    canManage() {
      return this.$store.getters["auth/canManage"];
    },
    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
    listComponent() {
      if (this.$refs && this.$refs.listComponent) {
        let item = this.$refs.listComponent;
        if (item && Array.isArray(item)) {
          item = item[0];
        }
        return item;
      }
      return null;
    },
  },
  components: { FbPostAvatar },
  mounted() {},
  emits: ["itemClick", "itemCountChanged"],
  methods: {
    childCountChanged(key, e) {
      this.itemCount[key] = e;
    },
    emitItemCount(e) {
      this.$emit("itemCountChanged", e);
    },
    refreshTable() {
      if (this.listComponent) {
        this.listComponent.updateTableData();
      }
    },
    emitClick(key) {
      this.$emit("itemClick", key);
    },
  },
};
</script>

<style lang="scss" scoped>
.fb-comment-list-container {
  display: flex;
  flex-direction: row;

  .main-post-container {
    max-width: 30rem;
    &::v-deep(.avatar-details) {
      margin: 0;
      max-width: 100%;
      .post-content {
        font-size: 1rem;
      }
    }
  }
  .fb-comment-list {
    width: 100%;
    .child-list-container {
      max-width: 25rem;
      background: white;
      font-size: 0.8rem;
      border-radius: 0.5rem;
      margin-top: -0.5rem;
      margin-left: 1rem;
      box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.15);

      &.hidden-list {
        display: none;
      }
    }
    &::v-deep(.fromId-data-container) {
      width: 1%;
      white-space: nowrap;
    }
    &::v-deep(.form-component-container) {
      display: inline-flex;
      form {
        display: flex;
        align-items: center;
        padding: 0 1rem;
      }
    }
  }
}

.date-box {
  margin: auto;
  background: #fff;
  color: #1e2a30;
  display: inline-block;
  font-size: 0.75rem;
  padding: 0.3rem 0.6rem;
  border-radius: 0.3rem;
}

.parent-comment-container {
  margin-right: auto;
}
</style>