<template>
  <div class="item-display-container" v-if="itemData">
    <div class="item-body">
      <div v-if="itemType == 'video'" class="video-container">
        <video class="item-content" controls width="250">
          <source :src="itemData">
        </video>
      </div>
      <div v-else-if="itemType == 'image' && itemData != null" class="image-container">
        <img class="item-content" :src="itemData" :alt="details" @error="(e) => manageError(e)" />
      </div>
      <div v-else-if="itemType == 'audio' && itemData != null" class="audio-container">
        <audio controls>
          <source :src="itemData" :alt="getAttachmenDetails">
        </audio>
      </div>
      <div v-else-if="itemType == 'notAvailable'" class="not-available-container">
        notAvailable
      </div>
      <div v-else-if="itemType == 'url' && itemData != null" class="url-container">
        <a :href="itemData" target="_blank" :alt="details">{{ details ?? itemType ?? 'unknown' }}</a>
      </div>
      <div v-else-if="itemType == 'location' && itemData != null" class="location-container">
        <a :href="`https://www.google.com/maps/@${itemData.lat},${itemData.lng},13z`" target="_blank"
          class="image-container">
          <img :src="itemData.thumb" :alt="details" />
        </a>
      </div>
      <div v-else-if="itemType == 'document'" class="document-container">
        <div class="image-container">
          <a v-if="itemData" :href="itemData.href" target="_blank">
          <img v-if="itemData != null" :src="itemData.thumb" :alt="details" />
          <div v-else class="document-icon-container">
            <i v-if="itemData?.mimetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
              class="fa-solid fa-table"></i>
            <i v-else-if="itemData?.mimetype == 'application/pdf'" class="fa-solid fa-table"></i>
            <i v-else class="fa-solid fa-file"></i>
          </div>
        </a>
        </div>
      </div>
      <div v-else class="extra-display-container">
        <slot name="extraDisplayOptions" :data="{ itemType,itemName,itemData,details }">
          <a v-if="itemData" :href="itemData" target="_blank">{{ details ?? itemType ?? 'unknown' }}</a>
          <span v-else>{{ details ?? itemType ?? 'unknown' }}</span>
        </slot>
      </div>
    </div>
    <div class="item-actions">
      <CustomButton v-if="showDownloadBtn" buttonClass="socialors-action-button download-button"
        @click="(e) => $emit('downloadMedia',e)" iconClass="fa-solid fa-download"></CustomButton>
      <CustomButton v-if="showNewTab" buttonClass="socialors-action-button show-in-popup-button"
        @click="(e) => $emit('showNewTab',e)" iconClass="fa-solid fa-share"></CustomButton>
    </div>
  </div>
</template>

<script>

export default {
  name: "ItemDisplayComponent",
  props: {
    itemData: { type: String,default: () => undefined },
    itemName: { type: String,default: () => undefined },
    itemType: { type: String,default: () => undefined },
    details: { type: String,default: () => undefined },
    showDownloadBtn: { type: Boolean,default: () => true },
    showNewTab: { type: Boolean,default: () => true },
  },
  data() {
    return {};
  },
  components: {},
  watch: {},
  mounted() { },
  emits: ['manageError','downloadMedia','showNewTab'],
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.item-display-container {
  display: flex;

  .item-body {
    max-width: 20rem;

    .item-content {
      max-width: 100%;
      margin: .5rem;
    }

    .document-container,
    .location-container {
      .image-container {
        max-height: 5rem;
        max-width: 5rem;
      }
    }

    .image-container {
      max-height: 10rem;
      max-width: 10rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: .3rem;

      img {
        width: 100%;
      }
    }
  }

  .item-actions{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .item-details-modal {
    &::v-deep(.item-display-container) {
      img,
      .item-content,
      .item-body,
      .image-container {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}
</style>