<template>
<div class="attachements-container">
  <div v-for="attachment,index in attachments" :key="index" class="attachment-item-container">
    <MessageAttachmentItemDisplay :attachment="attachment"  @downloadMedia="(e)=>$emit('downloadMedia',e,attachment)"  @showNewTab="(e)=>$emit('showNewTab',e,attachment)"/>
  </div>
</div>
</template>

<script>
import MessageAttachmentItemDisplay from "./MessageAttachmentItemDisplay.vue"

export default {
  name: "MessageAtachmentDisplayComponent",
  props: {
    attachments: { type: Array, default: ()=>[] },
  },
  data() {
    return {};
  },
  emits:['downloadMedia','showNewTab'],
  components: {MessageAttachmentItemDisplay},
  watch: {},
  mounted() {},
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>