import axios from "@/axios";
import { checkIdExist,storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/fb/comments',{
  actions: {
    markAsRead({ dispatch },{ id }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "PATCH",
          url: `/api/fb/comments/${id}/markAsRead`,
          params: {},
        })
        .then((d) => {
          return d.data;
        });
    },
    submitComment({ dispatch },{ id,data,tokenId }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/fb/comments/${id}/comments`,
          data,
          params: { tokenId },
        })
        .then((d) => {
          return d.data;
        });
    },
    deleteRemote({ dispatch },{ id }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "DELETE",
          url: `/api/fb/comments/${id}/remote`,
        })
        .then((d) => {
          return d.data;
        });
    },
    updateRemote({ dispatch },{ id,data }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/fb/comments/${id}/remote`,
          data
        })
        .then((d) => {
          return d.data;
        });
    },
    getCustomers({ dispatch },{ id,organisationId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/fb/comments/${id}/customers`,
          params: { organisationId,force }
        })
        .then((d) => {
          return d.data;
        });
    },
    addCustomer({ dispatch },{ id,organisationId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: 'POST',
          url: `/api/fb/comments/${id}/customers`,
          data: { organisationId },
          params: { force }
        })
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "FBCOMMENT"
});

export default module;
