import axios from "../../../axios";
import { checkIdExist,storeDefaultModule,} from "../../common";

const module = storeDefaultModule('/api/waweb/sessions',{
  actions: {
    getQrCode({ dispatch },{ id }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "GET",
          url: `/api/waweb/sessions/${id}/qr`,
        },false)
        .then((d) => d.data);
    },
    logout({ dispatch },{ id }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/waweb/sessions/${id}/logout`,
        })
        .then((d) => d.data);
    },
  },
  cacheKey: "WAWEBSESSION"
});

export default module;