<template>
  <CustomAvatar v-if="displaySimple == false" class="waweb-chat-avatar" :readyToDisplay="waWebChat != null"
    :dataIdentifier="id" :displaySmall="displaySmall" :imageUrl="imageUrl" :showDetails="showDetails"
    :showInCard="showInCard" :showImage="showImage" :actionMenuOptions="menuOptions" @actionMenuClick="clickMenuAction"
    @itemClick="navigateToInstaUser" @refreshClick="getWaWebChat(true)">
    <template v-slot:defaultImage>
      <i class="fa-solid fa-comments"></i>
    </template>
    <template v-slot:mainData>
      {{ name }}
    </template>
    <template v-slot:dataDetails>
      <div>
        <div v-if="waWebChat?.lastMessageId">
          <div class="message-date-container">
            {{ formatDate(waWebChat?.lastMessageDate) }}
          </div>
          <UserAvatar v-if="waWebChat.agentId" :userId="waWebChat.agentId" :displaySmall="true"></UserAvatar>
        </div>
        <div class="status-icons">
          <CustomTooltip text="Group Chat" position="bottom" v-if="waWebChat?.data?.group == true">
            <i class="fa-solid fa-user-group"></i>
          </CustomTooltip>
          <CustomTooltip text="Read Only" position="bottom" v-if="waWebChat?.data?.isReadOnly == true">
            <i class="fa-brands fa-readme"></i>
          </CustomTooltip>
          <CustomTooltip v-if="waWebChat?.data?.isMuted == true" text="Muted" position="bottom">
            <i class="fa-solid fa-bell-slash"></i>
          </CustomTooltip>
          <CustomTooltip text="Pinned" position="bottom" v-if="waWebChat?.data?.pinned == true">
            <i class="fa-solid fa-exclamation"></i>
          </CustomTooltip>

          <CustomTooltip text="Authenticated" position="bottom" v-if="waWebChat?.data?.pinned == true">
            <i class="fa-solid fa-exclamation"></i>
          </CustomTooltip>
          <CustomTooltip text="Unread" position="bottom" v-if="waWebChat?.unreadCount > 0">
            <span class="unread-count"> {{ waWebChat.unreadCount }}</span>
          </CustomTooltip>
        </div>
      </div>
    </template>
    <template v-slot:additionalButtons></template>
    <template v-slot:popupData>
      <div v-html="getObjectDefaultDisplay()"></div>
    </template>
  </CustomAvatar>
  <WaWebChatSimpleListAvatar v-else :waWebChat="waWebChat" :showPhoneInfo="showPhoneInfo"></WaWebChatSimpleListAvatar>
</template>

<script>
import { displayObjectData } from "@/helpers/common";
import { formatDate } from "@/helpers/common";
import UserAvatar from "../data/UserAvatar.vue";
import WaWebChatSimpleListAvatar from "../SimplifiedAction/WaWebChatSimpleListAvatar.vue";
export default {
  name: "WaWebChatAvatar",
  props: {
    organisationId: { type: String },
    id: { type: String },
    waWebPhoneId: { type: String },
    navigateOnClick: { type: Boolean,default: () => false },
    showInCard: { type: Boolean,default: () => false },
    showDetails: { type: Boolean,default: () => false },
    showImage: { type: Boolean,default: () => true },
    displaySmall: { type: Boolean,default: () => false },
    showShortDate: { type: Boolean,default: () => false },
    showPhoneInfo: { type: Boolean,default: () => false },
    displaySimple: { type: Boolean,default: () => false },
  },
  data() {
    return {
      waWebChat: undefined,
      menuOptions: [],
      imageUrl: undefined,
      name: undefined,
    };
  },
  watch: {
    id() {
      this.getWaWebChat();
    },
    "$store.state.lastUpdateNotification"(val) {
      if (
        val?.objectType?.match(/^WAWEBCHAT_/) &&
        val.data &&
        this.id &&
        Array.isArray(val.data) &&
        val.data?.find((x) => x._id == this.id) != null
      ) {
        this.getWaWebChat();
      }
    },
  },
  mounted() {
    this.getWaWebChat();
  },
  methods: {
    formatDate(val) {
      return formatDate(val,this.showShortDate ? "LT" : undefined);
    },
    clickMenuAction() { },
    navigateToInstaUser() {
      if (this.navigateOnClick == false) {
        return;
      }
      const query = {};
      if (this.organisationId) {
        query.organisationId = this.organisationId;
      }
      this.$router.push({
        name: "waWebChatDetails",
        params: { id: this.id },
        query,
      });
    },
    getWaWebChat(force = false) {
      this.$store
        .dispatch("waWebChat/getById",{
          id: this.id,
          organisationId: this.organisationId,
          waWebPhoneId: this.waWebPhoneId,
          force,
          mergeRequest: true
        })
        .then((chat) => {
          this.waWebChat = chat;
          this.name = chat.name;
          if (chat.waWebContactIds && chat.waWebContactIds.length == 1) {
            this.$store.dispatch("waWebContact/getById",{
              id: chat.waWebContactIds[0]
            }).then(contact => {
              this.imageUrl = contact?.imageUrl;
              this.name = contact?.name;
            })
          }
        });
    },
    getObjectDefaultDisplay() {
      return displayObjectData(this.waWebChat?.data ?? {});
    },
  },
  components: { UserAvatar,WaWebChatSimpleListAvatar },
};
</script>

<style lang="scss" scoped>
.unread-count {
  background-color: red;
  padding: 0.1rem 0.3rem;
  overflow: hidden;
  border-radius: 0.2rem;
  color: #fff;
  margin: 0.2rem;
}

.status-icons {
  display: flex;
  align-items: center;
  justify-content: center;

  .fa-solid {
    font-size: 0.9rem;
    color: gray;
    margin: 0.2rem;
    opacity: 0.9;

    &.active {
      opacity: 1;
      color: darkgreen;
    }
  }
}
</style>