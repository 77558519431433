<template>
  <slot :data="page">
    <CustomAvatar
      class="fb-page-avatar"
      :class="{ 'clickable-avatar': navigateOnClick }"
      :readyToDisplay="page != null"
      :dataIdentifier="pageId"
      :displaySmall="displaySmall"
      :imageUrl="page?.imageUrl"
      :showDetails="showDetails"
      :showInCard="showInCard"
      :showImage="showImage"
      :showImageOnlyDesc="showImageOnlyDesc"
      :actionMenuOptions="menuOptions"
      :hasLink="this.page?.data?.link != null"
      @actionMenuClick="clickMenuAction"
      @itemClick="navigateToPage"
      @linkClick="navigateToExternal"
      @refreshClick="getPage(true)"
    >
      <template v-slot:defaultImage>
        <i class="fa-solid fa-file"></i>
      </template>
      <template v-slot:mainData>
        {{ page.name }}
        <span class="passive-label" v-if="page.isPassive">{{
          $t("general.passive")
        }}</span>
      </template>
      <template v-slot:dataDetails>
        {{ page.email }}
      </template>
      <template v-slot:additionalButtons> </template>
      <template v-slot:popupData>
        <div v-html="getObjectDefaultDisplay()"></div>
      </template>
    </CustomAvatar>
  </slot>
</template>

<script>
import { displayObjectData, generateFacebookLinkUrl } from "@/helpers/common";

export default {
  name: "FbPageAvatar",
  props: {
    organisationId: { type: String },
    pageId: { type: String },
    navigateOnClick: { type: Boolean, default: () => false },
    showLink: { type: Boolean, default: () => false },
    showDetails: { type: Boolean, default: () => false },
    displaySmall: { type: Boolean, default: () => false },
    showInCard: { type: Boolean, default: () => false },
    showImage: { type: Boolean, default: () => true },
    showImageOnly: { type: Boolean, default: () => false },
  },
  data() {
    return {
      page: undefined,
      menuOptions: [],
      showImageOnlyDesc: undefined,
    };
  },
  watch: {
    pageId() {
      this.getPage();
    },
    showImageOnly() {
      if (this.page && this.showImageOnly) {
        this.showImageOnlyDesc = this.page.name;
      }
    },
    "$store.state.lastUpdateNotification"(val) {
      if (
        val &&
        this.page &&
        val.objectType == "FBPAGE_UPDATED" &&
        val.data &&
        Array.isArray(val.data) &&
        val.data.find((x) => x._id == this.page._id)
      ) {
        this.getPage();
      }
    },
  },
  mounted() {
    this.getPage();
  },
  methods: {
    clickMenuAction() {},
    navigateToExternal() {
      if (this.page?.data?.link) {
        return window.open(
          generateFacebookLinkUrl(this.page.data.link),
          "_blank"
        );
      }
    },
    navigateToPage() {
      if (this.navigateOnClick == false) {
        return;
      }
      const query = {};
      if (this.organisationId) {
        query.organisationId = this.organisationId;
      }
      this.$router.push({
        name: "pageDetails",
        params: { id: this.pageId },
        query,
      });
    },
    getPage(force = false) {
      if (this.pageId) {
        this.$store
          .dispatch("fbPage/getById", {
            id: this.pageId,
            organisationId: this.organisationId,
            force,    mergeRequest: true
          })
          .then((d) => {
            this.page = d;
            if (this.page && this.showImageOnly) {
              this.showImageOnlyDesc = this.page.name;
            }
          });
      }
      return;
    },
    getObjectDefaultDisplay() {
      return displayObjectData(this.page.data ?? {});
    },
  },
};
</script>

<style lang="scss" scoped>
.fb-page-avatar {
  display: flex;
  margin: 0.2rem;
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    &::v-deep(.vs-button) {
      .fa-brands {
        font-size: 1.2rem;
      }
    }
  }
  .avatar-img-container {
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    padding: 0;
    margin: 1px;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.15) !important;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
  }

  .avatar-cover-background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
  }

  & > * {
    padding: 0 0.2rem;
  }
}
</style>