<template>
  <CustomAvatar class="user-avatar" :class="{ 'passive-avatar': user?.status == 'Passive' }"
    :readyToDisplay="user != null" :dataIdentifier="userId" :displaySmall="displaySmall" :showDetails="showDetails"
    :showInCard="showInCard" :showImage="showImage" :actionMenuOptions="menuOptions" @actionMenuClick="clickMenuAction"
    @itemClick="navigateToAccount" @refreshClick="getUser(true)">
    <template v-slot:defaultImage>
      <i class="fa-solid fa-user"></i>
    </template>
    <template v-slot:mainData>
      {{ user?.profile?.name }}

    </template>
    <template v-slot:dataDetails>
      <div class="user-details-container" v-if="user">
        <slot>
          <span v-if="showRole == true || showRole == user.role">{{ user?.role }}</span>
          <span class="passive-label" v-if="user.status == 'Passive'">{{
            $t("general.passive")
          }}</span>
        </slot>
      </div>
    </template>
    <template v-slot:popupData>
      <div v-html="getObjectDefaultDisplay()"></div>
    </template>
  </CustomAvatar>
</template>

<script>
import { displayObjectData } from "@/helpers/common";
export default {
  name: "UserAvatar",
  props: {
    userId: { type: String },
    organisationId: { type: String },
    showImage: { type: Boolean,default: () => true },
    showDetails: { type: Boolean,default: () => false },
    navigateOnClick: { type: Boolean,default: () => false },
    showRole: { type: [Boolean,String],default: () => false },
    displaySmall: { type: Boolean,default: () => false },
    showInCard: { type: Boolean,default: () => false },
  },
  data() {
    return {
      user: undefined,
      menuOptions: [],
    };
  },
  watch: {
    userId() {
      this.getUser();
    },
    "$store.state.lastUpdateNotification"(val) {
      if (
        val &&
        this.user &&
        val.objectType == "USER_UPDATED" &&
        val.data &&
        Array.isArray(val.data) &&
        val.data.find((x) => x._id == this.user._id)
      ) {
        this.getUser();
      }
    },
  },
  mounted() {
    this.getUser();
  },
  methods: {
    clickMenuAction() { },
    navigateToAccount() {
      if (this.navigateOnClick == false) {
        return;
      }

      const query = {};
      if (this.organisationId) {
        query.organisationId = this.organisationId;
      }

      this.$router.push({
        name: "userDetails",
        params: { id: this.userId },
        query,
      });
    },
    getUser() {
      if (this.userId) {
        this.$store
          .dispatch("user/getById",{
            id: this.userId,
            organisationId: this.organisationId,
            mergeRequest: true
          })
          .then((d) => {
            this.user = d;
          });
      }
      return;
    },
    getObjectDefaultDisplay() {
      const displayObj = {
        profile: {
          name: this.user?.profile?.name,
          gender: this.user?.profile?.gender,
          email: this.user?.profile?.email,
        },
        status: this.user?.status,
        role: this.user?.role,
        language: this.user?.language,
        authOptions: {
          username: this.user?.authOptions?.username,
        },
      };

      return displayObjectData(displayObj);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-avatar {
  &.passive-avatar {
    opacity: 0.5;
  }
}
</style>