<template>
  <div class="button-container">
    <button :type="buttonType" :class="buttonDisplayClass" :disabled="disabled" @click="e => $emit('click',e)">
      <slot>
        <slot name="preButton"></slot>
        <i v-if="iconClass" :class="iconClass"></i>
        <span v-if="buttonText != null && $i18n != null" class="button-text">{{ checkI18nFormat }}</span>
      </slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "CustomButton",
  props: {
    label: { type: String },
    buttonType: { type: String,default: () => "button" },
    buttonClass: { type: [String,Object],default: () => "primary-button" },
    displayMini: { type: Boolean,default: () => false },
    buttonText: { type: [String, Number] },
    iconClass: { type: String },
    disabled: { type: Boolean,default: () => false },
  },
  emits: ["click"],
  methods: {
  },
  computed: {
    checkI18nFormat() {
      if (this.buttonText != null && typeof this.buttonText == "string" && this.$i18n && /\./.test(this.buttonText)) {
        return this.$i18n.te(this.buttonText) ? this.$i18n.t(this.buttonText) : this.buttonText;
      }
      return this.buttonText;
    },
    buttonDisplayClass() {
      if (this.buttonClass && typeof this.buttonClass == 'object') {
        return {
          ...this.buttonClass,
          'display-mini': this.displayMini
        }
      }
      return (this.buttonClass ?? "") + " " + (this.displayMini ? 'display-mini ' : '')
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  cursor: inherit;
}

.button-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.display-mini button {
    margin: 0;
    padding: 0.2rem;
    padding-left: 0.25rem;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0.2rem .2rem;
    padding: 0.5rem;
    padding-left: 0.55rem;

    border-radius: 0.5rem;
    border-width: 0;
    font-weight: 600;

    box-shadow: 0 2px 5px 0 rgba(var(--shadow-rgb), 0.26),
      0 2px 10px 0 rgba(var(--shadow-rgb), 0.16);

    &.flat-button {
      box-shadow: none;
      background-color: transparent;
      color: inherit;

      &:hover {
        box-shadow: 0 2px 5px 0 rgba(var(--shadow-rgb), 0.16),
        0 2px 10px 0 rgba(var(--shadow-rgb), 0.16);
      }
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 2px 5px 0 rgba(var(--shadow-rgb), 0.46),
        0 2px 10px 0 rgba(var(--shadow-rgb), 0.26);
    }

    &.success-button {
      background-color: rgba(var(--vs-success), 1);
      color: white;
    }

    &.primary-button {
      background-color: rgba(var(--vs-primary), 1);
      color: white;
    }

    &.danger-button {
      background-color: rgba(var(--vs-danger), .9);
      color: white;
    }
  }

}
</style>