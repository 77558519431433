<template>
  <select v-if="optionsValues" :value="modelValue" :disabled="disabled"
    @input="$emit('update:modelValue',$event.target.value)">
    <option v-for="option in optionsValues" :key="option.val" :value="option.val"> {{ option.label }}
    </option>
  </select>
</template>

<script>
export default {
  name: "WaWebLabelSelectorComponent",
  components: {},
  props: {
    organisationId: { type: String },
    waWebPhoneId: { type: String },
    modelValue: { type: String,default: () => "" },
    disabled: { type: Boolean,default: () => false },
    availableIds: { type: Array },
    nonAvailableIds: { type: Array },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      optionsValues: undefined
    };
  },
  mounted() {
    this.updateFbPageOptions();
  },
  methods: {
    updateFbPageOptions() {
      this.optionsValues = [];
      return (this.waWebPhoneId ? (this.$store
        .dispatch("waWebLabel/getAll",{
          waWebPhoneId: this.waWebPhoneId,
          organisationId: this.organisationId,
          inIds: this.availableIds,
          ninIds: this.nonAvailableIds,
          projection: { _id: 1,name: 1 }
        }).then(labels => {
          if (labels.data && labels.data.length > 0) {
            this.optionsValues = labels.data.map(x => {
              return { label: x.name,val: x._id };
            })
          }
        }))
        : (this.$store.dispatch("waWebPhone/getAll",{
          organisationId: this.organisationId,
          options: { sort: { name: 1 },projection: { _id: 1,name: 1 } },
        }).then(phones => Promise.all(
          phones.data.map(phone => this.$store
            .dispatch("waWebLabel/getAll",{
              waWebPhoneId: phone._id,
              organisationId: this.organisationId,
              inIds: this.availableIds,
              ninIds: this.nonAvailableIds,
              projection: { _id: 1,name: 1 }
            }).then(labels => {
              if (labels.data && labels.data.length > 0) {
                this.optionsValues = labels.data.map(x => {
                  return { label: x.name + (phones.length > 1 ? ` ${phone.name} ` : ''),val: x._id,phone: { _id: phone._id,name: phone.name } };
                })
              }
            }))
        )))).then(ops => {
          return ops.sort((a,b) => {
            let res = a.phone?._id?.localeCompare(b.phone?._id ?? '') ?? 0;
            if (res == 0) {
              res = a.label.localeCompare(b.label);
            }
          })
        });
    }
  }
}
</script>
<style lang="scss" scoped></style>