import axios from "@/axios";
import { checkIdExist,storeDefaultModule } from "../common";

const module = storeDefaultModule('/api/users',{
  actions: {
    generateApiKey({ dispatch },id) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/users/${id}/apikey`,
        })
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "USER"
});

export default module;
