import { clearCacheByUrlPart } from "../../axios";
import { storeDefaultModule } from "../common";

const module = storeDefaultModule('/api/customers',{ cacheKey: "CUSTOMER" });
const baseClearCachedData = module.actions.clearCachedData;
module.actions.clearCachedData = ({ dispatch },{ item,key }) => {
    return baseClearCachedData({ dispatch },{ item,key }).then(() => {
        clearCacheByUrlPart(new RegExp("/customer","gi"));
        return;
    })
}

export default module;
