<template>
  <div class="dropdown" ref="dropdownIcon" v-if="options && options.length > 0"
    :class="{ 'active': menuIsVisible,'display-mini': displayMini,'non-arrow': showArraw == false }">
    <label class="dropdown__face" @click="closeMenu()">
      <div class="dropdown__text">
        <slot name="buttonContainer">
          <i class="fa-solid fa-bars"></i>
        </slot>
      </div>
      <div class="dropdown__arrow" v-if="showArraw"></div>
    </label>

    <ul class="dropdown__items" :class="{ 'active': menuIsVisible }" :style="menuLocations">
      <li v-for="option in options" :key="getOptionKey(option)" :class="getOptionKey(option) + '-option-container'"
        @click="(e) => optionSelect(option,e)">
        <slot :name="getOptionKey(option)">
          <div>{{ getOptionText(option) }}</div>
        </slot>
      </li>
    </ul>
    <div class="dropdown__background" @click="closeMenu()" :class="{ 'active': menuIsVisible }"></div>
  </div>
</template>

<script>
export default {
  name: "CustomDropDownComponent",
  props: {
    options: { type: Array,default: () => [] },
    closeOnClick: { type: Boolean,default: () => true },
    showArraw: { type: Boolean,default: () => true },
    displayMini: { type: Boolean,default: () => false },
  },
  data() {
    return {
      menuIsVisible: false,
      menuLocations: {
        position: 'fixed',
        width: 'fit-content'
      },
    };
  },
  watch: {
    menuIsVisible(val) {
      if (val == true) {
        this.getMenuLocation();
      }
    },
    "$store.state.appScrolled"(val) {
      if (val == true) {
        this.getMenuLocation();
      }
    }
  },
  mounted() { },
  computed: {
    dropdownIcon() {
      if (this.$refs != null && this.$refs.dropdownIcon != null) {
        return this.$refs.dropdownIcon;
      }
      return null;
    }
  },
  emits: ["optionSelected"],
  methods: {
    getMenuLocation() {
      if (this.dropdownIcon != null && this.menuIsVisible == true) {
        const rec = this.dropdownIcon.getBoundingClientRect();
        if (rec) {
          this.menuLocations.left = Math.round(rec.left) + "px";
          this.menuLocations.top = (Math.round(rec.bottom) + 5) + "px";
        }
      }
    },
    closeMenu(enable) {
      enable = enable ?? !this.menuIsVisible;
      this.menuIsVisible = enable;
    },
    getOptionKey(val) {
      if (typeof val == "string") {
        return val;
      }
      return val?.key ?? val?.value ?? val?.text;
    },
    getOptionValue(val) {
      if (typeof val == "string") {
        return val;
      }
      return val?.value ?? val?.key ?? val?.text;
    },
    getOptionText(val) {
      if (typeof val == "string") {
        return val;
      }
      return val?.text ?? val?.value ?? val?.key;
    },
    optionSelect(val,e) {
      const emitVal = this.getOptionValue(val);
      if (emitVal && emitVal.length > 0) {
        this.$emit("optionSelected",{ val: emitVal,event: e });
        if (this.closeOnClick) {
          this.closeMenu(false);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  min-width: 4rem;
  margin: 0.2rem;
  position: relative;

  &.non-arrow {

    .dropdown__text {
      margin-right: 0;
    }
  }

  &.display-mini {
    min-width: 0;
    margin: 0;
    margin-bottom: 0.2rem;

    .dropdown__face {
      padding: .2rem;
    }

    .dropdown__text {
      margin-right: 0.7rem;
    }

    &.non-arrow {
      .dropdown__text {
        margin-right: 0;
      }
    }

    .dropdown__arrow {
      right: 0.7rem;
      width: 0.5rem;
      height: 0.5rem;
    }

    .dropdown__items {
      padding: .5rem;
    }
  }

  &.active {
    z-index: 10;
  }

  &__face,
  &__items {
    background-color: rgb(var(--input-background-color));
    padding: .5rem;
    border-radius: 1rem;
  }

  &__text {
    margin-right: 1.2rem;
    display: flex;
    justify-content: center;
  }

  &__face {
    display: block;
    position: relative;
    padding: .5rem;
    margin-right: 0;
    margin-bottom: 0;
    z-index: 2;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: black;
    opacity: .1;
    display: none;
    z-index: 1;

    &.active {
      display: block;
    }
  }

  &__items {
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    min-width: 8rem;
    list-style: none;
    list-style-type: none;
    visibility: hidden;
    z-index: 10;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.93, 0.88, 0.1, 0.8);


    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-size: .9rem;

    &.active {
      top: calc(100% + 0.5rem);
      visibility: visible;
      opacity: 1;
    }

    &>li {
      padding-bottom: .2rem;
      cursor: pointer;
      text-align: center;
      font-weight: 500;

      &:hover {
        text-shadow: 0 0 black;
      }
    }
  }

  &__arrow {
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    position: absolute;
    top: 50%;
    right: 1.1rem;
    width: 0.7rem;
    height: 0.7rem;
    transform: rotate(45deg) translateY(-50%);
    transform-origin: right;
  }
}
</style>