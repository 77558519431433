import axios from "@/axios";

export default {
  namespaced: "true",
  state: {},
  getters: {},
  actions: {
    services(_a,_b) {
      return axios
        .baseRequest({
          url: `/api/defaults/services`,
        })
        .then((d) => {
          return d.data;
        });
    },
  },
  mutations: {},
};
