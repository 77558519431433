import axios from "../../../axios";
import { checkIdExist, storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/wa/medias',{
  actions: {

  },
  cacheKey: "FBWAMEDIA"
});

export default module;

