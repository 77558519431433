<template>
  <div class="share-container">
    <div v-for="share,index in shares" :key="index" class="share-item-container">
      <div class="share-header">{{ share.name }}</div>
      <div class="share-body">
        <div v-if="share.description" class="share-desc">{{ share.description }}</div>
        <div v-if="isImageLink(share.link)">
          <img class="share-content" :src="share.link" :alt="share.name" />
        </div>
        <div v-else-if="isFolder(share.link)">
          <a :href="share.link" target="_blank"><i class="fa-solid fa-folder"></i></a>
        </div>
        <div v-else-if="isVideo(share.link)">
          <video class="attachment-content" controls width="250">
            <source :src="share.link">
          </video>
        </div>
        <div v-else-if="share.link != null">
          <a :href="share.link" target="_blank"><i class="fa-solid fa-link"></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageShareDisplayComponent",
  props: {
    shares: { type: Array,default: () => [] },
  },
  data() {
    return {};
  },
  components: {

  },
  watch: {},
  mounted() { },
  methods: {
    isImageLink(shareLink) {
      if (typeof shareLink == "string" && shareLink.length > 0) {
        return /.+\.(jpg|png|gif|jpeg|webp)/i.test(shareLink.trim());
      }
      return false;
    },
    isFolder(shareLink) {
      if (typeof shareLink == "string" && shareLink.length > 0) {
        return /.+\/[^.]+\/?$/i.test(shareLink.trim());
      }
      return false;
    },
    isVideo(shareLink) {
      if (typeof shareLink == "string" && shareLink.length > 0) {
        return /.+\.(mp4|mpeg4|mov|avi|webm)/i.test(shareLink.trim());
      }
      return false;
    }
  },
};
</script>

<style lang="scss" scoped>
.share-content {
  max-width: 20rem;
  max-height: 20rem;
}
</style>