import axios,{ clearCacheByUrlPart } from "@/axios";
import jwt_decode from "jwt-decode";
import { indexDbService,localStorageService } from "../../controllers/localStorageService";

export default {
  namespaced: "true",
  state: {
    user: undefined,
    stats: undefined,
    token: localStorageService.token ?? undefined,
  },
  getters: {
    canManage(state) {
      return state.stats?.canManage ?? false;
    },
  },
  actions: {
    login({ dispatch,commit },{ username,password }) {
      return axios
        .baseRequest(
          {
            url: "/api/auth/login",
            method: "POST",
            data: { username,password },
            notifyStatus: false
          },
          false
        )
        .then((d) => {
          if (!((d.data?.token?.length ?? 0) > 0)) {
            throw Error("EMPTY_TOKEN");
          }
          commit("updateToken",d.data.token);
          dispatch("getAllowed").catch(() => null);
          indexDbService.removeAllData().catch(() => { });

          return dispatch("status");
        })
        .catch((e) => {
          commit("updateStats",null);
          commit("updateToken",null);
          commit("updateAllowed",null);
          throw e;
        });
    },
    checkOrsLogin(_a,_sessionId) {
      return axios
        .baseRequest(
          {
            url: "/api/auth/login/ors",
            method: "GET",
          },
          false
        );
    },
    loginOrsWithSessionData({dispatch, commit}) {
      return axios
        .baseRequest(
          {
            url: "/api/auth/login/ors",
            method: "POST",
          },
          false
        ).then((d)=>{
          if (!((d.data?.token?.length ?? 0) > 0)) {
            throw Error("EMPTY_TOKEN");
          }
          commit("updateToken",d.data.token);
          dispatch("getAllowed").catch(() => null);
          indexDbService.removeAllData().catch(() => { });

          return dispatch("status");
        }).catch((e) => {
          commit("updateStats",null);
          commit("updateToken",null);
          commit("updateAllowed",null);
          throw e;
        });
    },
    loginOrsOtp(a,data) {
      return axios
        .baseRequest(
          {
            url: "/api/auth/login/ors/otp",
            method: "POST",
            data
          },
          false
        );
    },
    loginOrsAuth({ dispatch,commit },data) {
      return axios
        .baseRequest(
          {
            url: "/api/auth/login/ors/auth",
            method: "POST",
            data
          },
          false
        ).then((d) => {
          if (!((d.data?.token?.length ?? 0) > 0)) {
            throw Error("EMPTY_TOKEN");
          }
          commit("updateToken",d.data.token);
          dispatch("getAllowed").catch(() => null);
          indexDbService.removeAllData().catch(() => { });
          return dispatch("status");
        }).catch((e) => {
          commit("updateStats",null);
          commit("updateToken",null);
          commit("updateAllowed",null);
          throw e;
        });
    },
    status({ dispatch,commit },options = {}) {
      return axios
        .baseRequest(
          {
            url: "/api/auth/renew",
            method: "POST",
            notifyStatus: false,
            ...options,
          },
          false
        )
        .then((d) => {
          if (!((d.data?.token?.length ?? 0) > 0)) {
            throw Error("EMPTY_TOKEN");
          }
          commit("updateToken",d.data.token);
          commit("updateStats",d.data.stats);
          dispatch("getAllowed").catch(() => null);
          var decoded = jwt_decode(d.data.token);

          return dispatch(
            "user/getById",
            { id: decoded.userId },
            { root: true }
          )
            .then((u) => {
              commit("updateUser",u);
              commit("appAuthChecked",{},{ root: true });
              return d.data;
            })
            .catch((e) => {
              commit("appAuthChecked",{},{ root: true });
              throw e;
            });
        })
        .catch((e) => {
          commit("updateStats",null);
          commit("updateToken",null);
          commit("updateAllowed",null);
          commit("appAuthChecked",{},{ root: true });
          throw e;
        });
    },
    getAllowed({ commit },params) {
      return axios.baseRequest({
        url: "/api/auth/allowed",
        params,
      }).then(d => {
        commit("updateAllowed",d.data);
      });
    },
    connectToFbAccount(a,params) {
      return axios.baseRequest({
        url: "/api/auth/fblogin",
        method: "GET",
        params,
      });
    },
    submitFbAccount(a,data) {
      return axios.baseRequest({
        url: "/api/auth/fblogin",
        method: "POST", 
        data,
      });
    },
    logout({ commit }) {
      return axios
        .baseRequest(
          {
            url: "/api/auth/logout",
            method: "POST",
            notifyStatus: false
          },
          false
        )
        .then((d) => {
          commit("updateUser",null);
          commit("updateToken",null);
          commit("updateAllowed",null);
          clearCacheByUrlPart(/./);
          return d.data;
        });
    },
    updateProfile({ dispatch },data) {
      return axios
        .baseRequest(
          {
            url: "/api/auth/profile",
            method: "POST",
            data: { $set: data },
          },
          false
        )
        .then((d) => {
          dispatch("status");
          return d;
        });
    },
  },
  mutations: {
    updateToken(state,data) {
      state.token = data;
      localStorageService.token = data;
    },
    updateAllowed(state,data) {
      state.allowed = data;
    },
    updateUser(state,data) {
      state.user = data;
    },
    updateStats(state,data) {
      state.stats = data;
    },
  },
};
