<template>
  <div class="form-fields-group">
    <slot></slot>
    <div v-for="field in displayFields" :key="field.key" class="form-field-container"
      :class="{ [field.type + '-field-container']: field.hidden != true }">
      <label v-if="field.hidden != true && field.label">
        <div v-html="field.label"></div>
      </label>
      <div v-if="isArrayValue(field)">
        <CustomButton buttonClass="success-button" iconClass="fa-solid fa-plus" @click="generateNewItem(field)">
        </CustomButton>
        <div v-for="item in this.displayValue[field.key]" :key="getItemKey(field,item)" class="mini-label">
          <span @click="updateSelectedItem(field,item)">
            {{ itemNames && itemNames[field.key] ? itemNames[field.key][getItemKey(field,item)] : "-" }}
          </span>
          <span class="remove-selector" @click.stop="removeItem(field,item)">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>
      <div v-else>
        <CustomFormFieldDisplay v-if="field.hidden != true && displayValue != null" :field="field"
          v-model="displayValue[field.key]" @changeFieldValue="(d,additional) => manageFieldChange(field,d,additional)"
          @changeFieldDisplay="(d) => manageSubFieldChange(field,d)">
          <template #[field.type]>
            <slot :name="field.type"></slot>
          </template>
        </CustomFormFieldDisplay>
      </div>

    </div>
  </div>
  <CustomPopup ref="addOrUpdatePopup" :showButton="false" :title="$i18n.t('general.add')">
    <CustomForm v-if="addOrUpdateSelectedItem" :fields="updateFields" :initialValue="updateItem"
      @formSubmit="(val,e) => addItem(val,e)"></CustomForm>
  </CustomPopup>
  <ConfirmationDialog ref="deletePopup" dialogType="confirm" dialogColor="danger"
    @confirmed="(e) => removeItemConfirmed(e)" :displayInitial="false"></ConfirmationDialog>
</template>

<script>
import { manageFormSubmitButtons } from '../helpers/common';
import CustomFormFieldDisplay from './CustomFormFieldDisplay.vue';

export default {
  name: "CustomFormInputGroupCmp",
  props: {
    fields: { type: Array,default: () => undefined },
    modelValue: { type: [String,Number,Boolean,Object] },
    disabled: { type: Boolean,default: () => false },
  },
  components: { CustomFormFieldDisplay },
  data() {
    return {
      displayValue: undefined,
      displayFields: undefined,
      addOrUpdateSelectedItem: undefined,
      removeSelectedItem: undefined,
      itemNames: {},
      updateItem: undefined,
      updateFields: undefined,
    };
  },
  watch: {
    fields() {
      this.populateFields();
    },
    modelValue() {
      this.populateFields();
    },
  },
  mounted() {
    this.populateFields();
  },
  computed: {
    addOrUpdatePopup() {
      return this.$refs['addOrUpdatePopup'];
    },
    deletePopup() {
      return this.$refs['deletePopup'];
    }
  },
  emits: ["update:modelValue","changeFieldDisplay"],
  methods: {
    generateNewItem(field) {
      this.addOrUpdateSelectedItem = undefined;
      this.updateItem = undefined;
      this.updateFields = undefined;

      if (this.addOrUpdatePopup.showPopup) {
        this.addOrUpdateSelectedItem = { field };
        this.updateItem = field.defaultValue;
        this.updateFields = field.fields ?? [field];
        this.addOrUpdatePopup.showPopup();
      }
    },
    updateSelectedItem(field,item) {
      this.addOrUpdateSelectedItem = undefined;
      this.updateItem = undefined;
      this.updateFields = undefined;

      if (this.addOrUpdatePopup.showPopup) {
        this.addOrUpdateSelectedItem = { field,item };
        this.updateItem = item;
        this.updateFields = field.fields ?? [field];
        this.addOrUpdatePopup.showPopup();
      }
    },
    addItem(val,e) {
      manageFormSubmitButtons(e,() => {
        if (
          this.addOrUpdateSelectedItem &&
          this.addOrUpdateSelectedItem.field &&
          this.isArrayValue(this.addOrUpdateSelectedItem.field)
        ) {
          const base = this.addOrUpdateSelectedItem.field.item ?? val;
          const key = this.addOrUpdateSelectedItem.field.getItemKey(base);
          this.displayValue[this.addOrUpdateSelectedItem.field.key] = this.displayValue[this.addOrUpdateSelectedItem.field.key] ?? [];
          let index = this.displayValue[this.addOrUpdateSelectedItem.field.key].findIndex((x) => this.addOrUpdateSelectedItem.field.getItemKey(x) == key);

          if (this.addOrUpdateSelectedItem.field.fields == null) {
            val = val[this.addOrUpdateSelectedItem.field.key];
          }

          if (index == -1) {
            index = this.displayValue[this.addOrUpdateSelectedItem.field.key].push(val);
            index = index - 1;
          } else {
            this.displayValue[this.addOrUpdateSelectedItem.field.key][index] = {
              ...(this.displayValue[this.addOrUpdateSelectedItem.field.key][index] ?? {}),
              ...(val ?? {})
            }
          }

          this.getItemName(this.addOrUpdateSelectedItem.field,this.displayValue[this.addOrUpdateSelectedItem.field.key][index]);
          this.displayValue[this.addOrUpdateSelectedItem.field.key].sort((a,b) => this.addOrUpdateSelectedItem.field.getItemKey(a) - this.addOrUpdateSelectedItem.field.getItemKey(b))
        }
        if (this.addOrUpdatePopup.closePopup) {
          this.addOrUpdatePopup.closePopup();
        }
      });
    },
    removeItem(field,item) {
      if (this.deletePopup.displayDialog) {
        this.removeSelectedItem = { item,field };
        this.deletePopup.displayDialog();
      }
    },
    removeItemConfirmed() {
      if (
        this.removeSelectedItem &&
        this.removeSelectedItem.item &&
        this.removeSelectedItem.field &&
        this.isArrayValue(this.removeSelectedItem.field)
      ) {
        this.displayValue[this.removeSelectedItem.field.key] = this.displayValue[this.removeSelectedItem.field.key].filter(x => x != this.removeSelectedItem.item);
      }
    },
    getItemKey(field,item) {
      let key = item;
      if (field && field.getItemKey && typeof field.getItemKey == 'function') {
        key = field.getItemKey(item);
      }
      return key;
    },
    isArrayValue(field) {
      return field != null && (
        (this.displayValue[field.key] && Array.isArray(this.displayValue[field.key])) ||
        (field.defaultValue && Array.isArray(field.defaultValue))
      );
    },
    async populateFields() {
      this.displayValue = {};
      this.itemNames = {};
      let tempFields = [...(this.fields ?? [])];

      for (let index = 0;index < tempFields.length;index++) {
        const field = tempFields[index];
        if (field.type == null) {
          field.type = "text";
        }
        if (field.type == 'select' && field.optionsValues == null) {
          field.optionsValues = await Promise.resolve(this.getOptions(field));
        }

        this.displayValue[field.key] = this.modelValue && this.modelValue[field.key] != null
          ? this.modelValue[field.key]
          : field.defaultValue;

        if (this.isArrayValue(field)) {
          this.displayValue[field.key].map(x => this.getItemName(field,x))
        }

        if (field.changeAction && typeof field.changeAction == 'function') {
          const t = field.changeAction(this.displayValue[field.key],field,this.fields,undefined,this.displayValue);
          tempFields = t[0];
        }
      }
      this.displayFields = tempFields.filter(x => x.hidden != true);
    },
    async getItemName(field,item) {
      let name = item;
      if (field && field.getItemName && typeof field.getItemName == 'function') {
        const t = field.getItemName(item);
        if (t && t instanceof Promise) {
          name = await t;
        } else {
          name = t;
        }
      }
      let key = this.getItemKey(field,item);
      this.itemNames[field.key] = this.itemNames[field.key] ?? {};
      this.itemNames[field.key][key] = name
      return;
    },
    async getOptions(field) {
      if (field.options) {
        if (typeof field.options == "function") {
          const t = field.options();
          if (t instanceof Promise) {
            return await t;
          }
          return t;
        }
        return field.options;
      }
      return [];
    },
    manageFieldChange(field,data,additionalData) {
      this.displayValue[field.key] = data;
      if (field && field.changeAction && typeof field.changeAction == 'function') {
        const [updatedFields,updatedValue] = field.changeAction(data,field,this.fields,additionalData,this.displayValue);
        this.displayValue = updatedValue;
        this.displayFields = updatedFields;
        this.$emit("changeFieldDisplay",this.displayFields);
      }

      this.$emit("update:modelValue",this.displayValue);
    },
    manageSubFieldChange(field,updatedSubFields) {
      if (field && field.fields) {
        const updatedFields = [...this.displayFields];
        const updateIndex = this.displayFields.findIndex(x => x == field);
        if (updateIndex > -1) {
          updatedFields[updateIndex].field = updatedSubFields;
          this.displayFields = updatedFields;
          this.$emit("changeFieldDisplay",this.displayFields);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-field-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: .3rem .2rem;

  label {
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-size: 1rem;
  }

  &.checkbox-field-container {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;

    label {
      margin: 0.2rem 0.5rem 0 0.2rem;
    }
  }


  .mini-label {
    display: inline-flex;
    border-radius: .5rem;
    padding: .3rem .5rem;
    margin: .2rem .3rem;
    font-size: .7rem;
    font-weight: 600;
    background-color: rgba(var(--vs-primary), .9);
    color: rgba(var(--input-light-color), .9);
    cursor: pointer;
  }
}
</style>