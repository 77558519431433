<template>
    <div>
        <div v-if="showButton" class="open-button-container" @click.stop="(e) => showPopup(e)">
            <slot name="buttonContainer">
                <CustomButton buttonClass="success-button" :buttonText="buttonText ?? $i18n.t('general.show')">
                </CustomButton>
            </slot>
        </div>
        <vs-popup class="holamundo" :title="title ?? $i18n.t('general.update')" :style="{ 'z-index': zIndex + 101 }"
            v-model:active="popupActive">
            <slot v-if="popupActive"></slot>
        </vs-popup>
    </div>
</template>

<script>
export default {
    name: "CustomPopupComponent",
    components: {},
    props: {
        defaultOpen: { type: Boolean,default: () => false },
        showButton: { type: Boolean,default: () => true },
        buttonText: { type: String },
        title: { type: String },
    },
    data() {
        return {
            popupActive: false,
            zIndex: 0,
        };
    },
    mounted() {
        this.popupActive = this.defaultOpen;
    },
    watch: {
        popupActive(val) {
            if (val == false) {
                this.$emit("modalClosed");
            }
            else if (val == true) {
                this.$emit("modalOpen");
            }
        },
    },
    emits: ["modalClosed","modalOpen"],
    computed: {},
    methods: {
        togglePopup() {
            this.popupActive = !this.popupActive;
        },
        showPopup() {
            this.popupActive = true;
            this.zIndex = Math.max(...Array.from(document.querySelectorAll(".con-vs-popup:not([style*=\"display: none\"])"),(el) => parseFloat(window.getComputedStyle(el).zIndex)).filter((zIndex) => !Number.isNaN(zIndex)),20001);
        },
        closePopup() {
            this.popupActive = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.open-button-container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}
</style>