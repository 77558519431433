<template>
  <div class="tooltip-container" 
    :class="{'active': tooltipVisible }"
    
    @mouseover="(e)=>manageMouseEvents(e)" 
    @mouseenter="(e)=>manageMouseEvents(e)" 
    @mouseleave="(e)=>manageMouseEvents(e)">
    <div class="tooltip-face" ref="tooltipface">
      <slot></slot>
    </div>
    <div 
      class="tooltip-content" 
      :class="position+'-tooltip'"
      :style="contentStyle"
      >
      <slot name="content">
        <span>{{ text }}</span>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomTooltip",
  props: {
    text: { type: String },
    position: { type: String, default: ()=>"bottom" },
    active: { type: Boolean, default: () => true },
  },
  emits: ["click"],
  data() {
    return {
      tooltipVisible: false,
      contentStyle:{},
    }
  },
  methods: {
    manageMouseEvents(event) {
      if (this.active != true) {
        this.tooltipVisible = false;
        return false;
      }
      if (event.type) {
        var el = this.$refs['tooltipface'];
        var rect = el.getBoundingClientRect();
        this.contentStyle = {
          top: rect.bottom + 'px',
          left: rect.left + 'px',
          width: rect.width + 'px'
        }
        switch (event.type) {
          case "mouseover":
            this.tooltipVisible = true;
            break;
          case "mouseenter":
            this.tooltipVisible = true;
            break;
          case "mouseleave":
            this.tooltipVisible = false;
            break;
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>

.tooltip-container{
  position: relative;

  &.active{
    .tooltip-face{
      background-color: rgba($color:black, $alpha:.1);
    }
     .tooltip-content{
      display: inherit;
     }
  }

  .tooltip-face{
    display: block;
    position: relative;
    padding:.2rem;
    margin-right: 0;
    margin-bottom: 0;
    border-radius: .5rem;
    z-index: 2;
    cursor: cursor;
  }

  .tooltip-content{
    position: fixed;
    display: none;

    background-color:rgb(var(--input-background-color));
    padding: .5rem;
    border-radius: 1rem;

    top: calc(100%);
    left: 0; 
    right: 0; 

    min-width: 8rem;
    font-size:.9rem;

    z-index: 10;
    transition: all 0.4s cubic-bezier(0.93, 0.88, 0.1, 0.8);

  }
}
</style>