<template>
  <div v-if="post" class="simple-data-display" :class="{
    'not-read-post': (post?.unreadCommentCount ?? 0) > 0,
  }" @click="(e) => emitClick(e)">
    <div class="page-container" v-if="showPageInfo">
      <FbPageAvatar :pageId="post.fbPageId" :displaySmall="true" :showImage="false"></FbPageAvatar>
      <div class="post-info-container">
        <div class="post-type-container">
          <div class="unread" v-if="post.isAnswered == false">
            <span class="numb danger-numb"><i class="fa-solid fa-exclamation"></i></span>
          </div>
          <div class="unread" v-if="post.unreadCommentCount">
            <span class="numb">{{ post.unreadCommentCount }}</span>
          </div>
        </div>
        <div class="post-date-container">
          <span>{{ formatDate(post.lastCommentDate) }}</span>
        </div>
        <i class="fa-brands fa-facebook"></i>
      </div>
    </div>
    <div class="simple-header">
      <div class="participant-container">
        <CustomAvatar :readyToDisplay="post != null" :dataIdentifier="post._id" :imageUrl="imageUrl">
          <template v-slot:mainData>
            {{ postMainMessage }}
          </template>
          <template v-slot:dataDetails>
            <div class="lastcomment" v-if="lastComment">
              <i v-if="lastComment?.isRead != true" class="fa-solid fa-check"
                :class="{ 'not-read': lastComment?.isRead != true }"></i>
              <i v-else class="fa-solid fa-check-double"></i>
              <span> {{ lastComment.message }}</span>
            </div>
          </template>
        </CustomAvatar>
      </div>
    </div>
  </div>
</template>

<script>
import FbPageAvatar from "@/components/facebook/FbPageAvatar.vue";

import { formatDate } from "@/helpers/common";
export default {
  name: "FbPostSimpleListAvatar",
  props: {
    post: { type: Object },
    showPageInfo: { type: Boolean,default: () => true },
    showPageImage: { type: Boolean,default: () => false },
  },
  data() {
    return {
      postOwnerFbUser: undefined,
      lastCommentFbUser: undefined,
      lastComment: undefined,
      imageUrl: undefined,
      postMainMessage: undefined,
    };
  },
  components: {
    FbPageAvatar,
  },
  watch: {
    post() {
      this.updateData();
    },
    showPageImage() {
      this.updateData();
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    async updateData() {
      this.imageUrl = undefined;
      this.postOwnerFbUser = undefined;
      this.lastCommentFbUser = undefined;
      this.postMainMessage = undefined;

      if (this.post) {
        if (
          !this.isValidContent(this.postMainMessage) &&
          this.isValidContent(this.post.message)
        ) {
          this.postMainMessage = this.post.message;
        }

        if (this.post?.fbPageId) {
          this.$store
            .dispatch("fbPage/getById",{
              id: this.post.fbPageId,mergeRequest: true
            })
            .then((d) => {
              if (this.showPageImage) {
                if (this.isValidContent(d.imageUrl)) {
                  this.imageUrl = d.imageUrl;
                }
              }
              if (
                !this.isValidContent(this.imageUrl) &&
                this.isValidContent(this.post.photoUrl)
              ) {
                this.imageUrl = this.post.photoUrl;
              }
              if (this.post?.fromId) {
                this.$store
                  .dispatch("fbUser/getById",{
                    id: this.post.fromId,
                    mergeRequest: true
                  })
                  .then((d) => {
                    this.postOwnerFbUser = d;
                    if (d == null) {
                      return;
                    }
                    if (
                      !this.isValidContent(this.postMainMessage) &&
                      this.isValidContent(d.name)
                    ) {
                      this.postMainMessage = d.name;
                    }
                    if (
                      !this.isValidContent(this.imageUrl) &&
                      this.isValidContent(d.imageUrl)
                    ) {
                      this.imageUrl = d.imageUrl;
                    }
                  });
              }
            });
        }

        if (this.post?.lastCommentId) {
          this.$store
            .dispatch("fbComment/getById",{
              id: this.post?.lastCommentId,
              mergeRequest: true
            })
            .then((c) => {
              this.lastComment = c;
            });
        }
      }
    },
    isValidContent(text) {
      return text && text.length > 0;
    },
    formatDate(val) {
      return formatDate(val,undefined,true);
    },
    emitClick(e) {
      this.$emit("itemClick",e);
    },
  },
};
</script>

<style lang="scss" scoped>
.unread {
  .numb {
    &.danger-numb{
      background: inherit;
      color: inherit;
    }
    background: #25d366;
    color: #fff;
    font-weight: 500;
    min-width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    margin-right: 0.4rem;
  }
}

.post-date-container {
  display: flex;
  align-items: flex-end;
  padding: 0 0.5rem;
  font-size: 0.7rem;
  font-weight: 400;


}

.simple-data-display {
  display: flex;
  overflow: hidden;
  flex: 1 0;
  flex-direction: column;
  border-radius: 0.5rem;
  cursor: pointer;
  max-width: 20rem;

  @media (max-width: 800px) {
    max-width: 100%;
  }

  &::v-deep(.avatar-container) {
    max-width: 100%;
    overflow: hidden;
    flex: 1 1 auto;

    .avatar-details {
      overflow: hidden;
      flex: 1 1 auto;
    }

    .main-data-container {
      font-size: 0.8rem;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .data-details-container {
      display: flex;
      align-items: center;

      .lastcomment {
        flex: 1 1 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .simple-header {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    max-width: 100%;
    width: 100%;

    .participant-container {
      display: flex;
      overflow: hidden;
      flex: 1 1 auto;
    }
  }

  .page-container {
    justify-content: center;
    display: flex;
    max-width: 100%;
    width: 100%;
  }

  .post-info-container {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .post-type-container {
      justify-content: center;
      display: flex;
      align-items: center;
      padding: 0.3rem;
      margin-left: auto;
    }
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>