import axios from "@/axios";
import { checkIdExist, storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/fb/messages',{
  actions: {
    getAttachments({ dispatch },{ organisationId,pageId,conversationId,id,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest(
          {
            url: `/api/fb/messages/${id}/attachments`,
            params: { organisationId,pageId,conversationId,force },
          },
          !(force == true)
        )
        .then((d) => {
          return d.data;
        });
    },
    getShares({ dispatch },{ organisationId,pageId,conversationId,id,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest(
          {
            url: `/api/fb/messages/${id}/shares`,
            params: { organisationId,pageId,conversationId,force },
          },
          !(force == true)
        )
        .then((d) => {
          return d.data;
        });
    },
    submit({ dispatch },{ conversationId,commentId,postId,fbUserId,pageId,messageId,data }) {
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/fb/messages/submit`,
          data,
          params: { conversationId,commentId,postId,fbUserId,pageId,messageId },
        })
        .then((d) => {
          dispatch('clearCachedData',{ item: d.data,key: "FBMESSAGE_ADD" });
          return d.data;
        });
    },
    markAsRead({ dispatch },{ id }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "PATCH",
          url: `/api/fb/messages/${id}/markAsRead`,
        })
        .then((d) => {
          dispatch('clearCachedData',{ item: d.data,key: "FBMESSAGE_UPDATED" });
          return d.data;
        });
    },
  },
  cacheKey: "FBMESSAGE"
});

export default module;
