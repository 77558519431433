import axios from "../../../axios";
import { storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/wa/messages',{
  actions: {
    submit({ dispatch },{ wabaId,fbWaContactId,fbWaMessageId,fbWaPhoneId,data }) {
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/wa/messages/submit`,
          data,
          params: { 
            wabaId,
            fbWaContactId,
            fbWaMessageId,
            fbWaPhoneId 
          },
        })
        .then((d) => {
          dispatch('clearCachedData',{ item: d.data,key: "WAMESSAGE_ADD" });
          return d.data;
        });
    },
  },
  cacheKey: "FBWAMESSAGE"
});

export default module;