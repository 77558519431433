import axios from "@/axios";
import { checkIdExist,storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/fb/conversations',{
  actions: {
    markAsRead({ dispatch },{ id }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "PATCH",
          url: `/api/fb/conversations/${id}/markAsRead`,
        })
        .then((d) => {
          return d.data;
        });
    },
    getCustomers({ dispatch },{ id,organisationId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/fb/conversations/${id}/customers`,
          params: { organisationId,force }
        })
        .then((d) => {
          return d.data;
        });
    },
    addCustomer({ dispatch },{ id,organisationId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: 'POST',
          url: `/api/fb/conversations/${id}/customers`,
          data: { organisationId },
          params: { force }
        })
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "FBCONVERSATION"
});

export default module;
