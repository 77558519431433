<template>
 <div>
  <input
    type="text"
    :value="displayValue"
    :disabled="disabled"
    :list="listKey"
    @input="displayedValueChanged($event)"
    />
    <datalist :id="listKey">
      <option v-for="option in displayOptions" 
      :key="option.value" 
      :value="option.value">{{ option.label }}</option>
    </datalist>
 </div>
</template>

<script>
export default {
  name: "UserSelectorComponent",
  props: {
    key: { type: String },
    forceToSelect: { type: Boolean, default:()=>false },
    listKey: { type: String },
    modelValue: { type: String },
    disabled:{ type:Boolean, default: false},
    options:{ type: [Array, Function,Promise], default: ()=>[]}
  },
  emits: ['update:modelValue'],
  data() {
    return {
      displayValue: undefined,
      displayOptions: undefined,
      dataListDisplay: undefined
    };
  },
  mounted() {
    this.getDisplayOptions(this.options);
  },
  watch: {
    modelValue() {
      this.displayValue = this.getValidDataLabel(this.modelValue);
    },
    displayOptions() {
      // this.displayValue = this.getValidDataLabel(this.modelValue);
    },
    options(val) {
      this.getDisplayOptions(val);
    },
  },
  methods: {
    getValidDataOption(val) { 
      return this.displayOptions?.find(x =>
        typeof x == 'string' ?
        x == val :
          (x.value != null ? x.value == val : x.label == val)
      );
    },
    getValidDataLabel(val) {
      const selectedOption = this.getValidDataOption(val);
      if (selectedOption) {
        return typeof selectedOption == 'string' ? selectedOption : (selectedOption?.label ?? selectedOption?.value);
      }
      return;
    },
    getValidDataValue(val) {
      const selectedOption = this.getValidDataOption(val);
      if (selectedOption) {
        return typeof selectedOption == 'string' ? selectedOption : (selectedOption?.value ?? selectedOption?.label);
      }
      return;
    },
    displayedValueChanged(event) {
      const val = event.target.value;
      const validValue = this.getValidDataValue(val);
      if (validValue) {
        const validLabel = this.getValidDataLabel(val);
        this.displayValue = validLabel;
        this.valueChanged(validValue); 
      } else {
        this.getDisplayOptions(this.options,val);
        this.displayValue = val;
      }
    },
    valueChanged(val) {
      if(this.modelValue != val){
        this.$emit('update:modelValue',val);
      }
    },
    async getDisplayOptions(val, search) {
      if (typeof val == 'function') {
        val = val(search ?? this.modelValue);
      }
      if (val instanceof Promise) {
        this.displayOptions = await val;
        return;
      } else {
        this.displayOptions = val;
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
datalist {
  position: absolute;
  background-color: white;
  border: 1px solid blue;
  border-radius: 0 0 5px 5px;
  border-top: none;
  font-family: sans-serif;
  width: 350px;
  padding: 5px;

}

option {
  background-color: white;
  padding: 4px;
  color: blue;
  margin-bottom: 1px;
   font-size: 18px;
  cursor: pointer;
}
</style>