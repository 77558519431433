import axios from "@/axios";
import { checkIdExist,storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/fb/applications',{
  actions: {
    getInfo({ dispatch },{ id,organisationId }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          params: { organisationId },
          url: `/api/fb/applications/${id}/info`,
        })
        .then((d) => {
          return d.data;
        });
    },
    getByToken({ dispatch },{ tokenId }) {
      checkIdExist(tokenId,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/fb/applications/bytoken/${tokenId}`,
        })
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "FBAPPLICATION"
});

export default module;
