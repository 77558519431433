<template>
    <select
      v-if="optionsValues"
      :value="modelValue"
      :disabled="disabled"
      @input="$emit('update:modelValue', $event.target.value)"
    >
      <option
        v-for="option in optionsValues"
        :key="option.val"
        :value="option.val"
      > {{option.label}} 
      </option>
    </select>
</template>

<script>
export default {
  name: "ChannelSelectorComponent",
  components: {},
  props: {
    organisationId: { type: String },
    modelValue: { type: String,default: () => "" },
    disabled: {type: Boolean, default: ()=>false},
    allowUnselected: {type: Boolean, default: ()=>false},
    availableIds: { type: Array },
  },
  emits:['update:modelValue'],
  data() {
    return {
      optionsValues: undefined
    };
  },
  watch:{
    organisationId(){
      this.updateOptions();
    }
  },
  mounted() {
    this.updateOptions();
  },
  methods: {
    updateOptions() {
      return this.$store.dispatch("channel/getAll", {
        organisationId: this.organisationId,
        options: {sort:{name:1}, projection: { _id: 1, name:1 } },
      }).then(d => {
        if (d.data && d.data.length > 0) {
          this.optionsValues = d.data.map(x => {
            return { label: x.name,val: x._id };
          })

          if(this.allowUnselected && this.optionsValues.find(x=>x.val == null) == null) {
            this.optionsValues = [ { label: '',val: null }, ...(this.optionsValues ?? []) ];
          }
        } else {
          this.optionsValues = [];
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
</style>