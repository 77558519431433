import axios from "@/axios";
import { checkIdExist, storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/ig/messages',{
  actions: {
    getAttachments({ dispatch },{ organisationId,pageId,igConversationId,id,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest(
          {
            url: `/api/ig/messages/${id}/attachments`,
            params: { organisationId,pageId,igConversationId,force },
          },
          !(force == true)
        )
        .then((d) => {
          return d.data;
        });
    },
    getShares({ dispatch },{ organisationId,pageId,igConversationId,id,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest(
          {
            url: `/api/ig/messages/${id}/shares`,
            params: { organisationId,pageId,igConversationId,force },
          },
          !(force == true)
        )
        .then((d) => {
          return d.data;
        });
    },
    submit({ dispatch },{
      igCustomerUserId,
      igConversationId,
      igMessageId,
      igMediaId,
      igCommentId,
      pageId,
      tokenId,
      data }) {
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/ig/messages/submit`,
          data,
          params: {
            igCustomerUserId,
            igConversationId,
            igMessageId,
            igMediaId,
            igCommentId,
            tokenId,
            pageId
          },
        })
        .then((d) => {
          dispatch('clearCachedData',{ item: d.data,key: "FBIGMESSAGE_ADD" });
          return d.data;
        });
    },
    markAsRead({ dispatch },{ id }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "PATCH",
          url: `/api/ig/messages/${id}/markAsRead`,
        })
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "FBIGMESSAGE"
});

export default module;
