<template>
<FbLabelAvatar :initialData="data" :showDetails="false" :showInfo="false"></FbLabelAvatar>
    <div class="waweb-label-container" v-if="data">
      <vs-tabs alignment="center" class="count-container">
        <vs-tab :label="$t('general.details')">
          <div class="custom-data-display">
            {{ data }}
            <div v-html="getObjectDefaultDisplay()"></div>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
</template>

<script>
import { displayObjectData } from '../../helpers/common';

export default {
  components: {  },
  props: {
      initialData: undefined,
      id: undefined,
      organisationId: undefined,
  },
  data() {
    return {
      data: undefined
    };
  },
  watch: {
    id() {
    },
    initialData() {
    },
  },
  computed: {
    dataId() {
      return this.data?._id ?? this.initialData?._id ?? this.id;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(force = false) {
      if (force != true && this.initialData != null) {
        this.data = this.initialData;
        return this.data;
      }
      return this.$store
        .dispatch("fbLabel/getById", {
          id: this.id,
          organisationId: this.organisationId,
           mergeRequest: true,
          force,
        })
        .then((d) => {
          this.data = d;
        });
    },
    getObjectDefaultDisplay() {
      return displayObjectData(this.data?.data ?? {});
    },
  },
};
</script>
