import axios from "../../../axios";
import { checkIdExist, storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/waweb/messages',{
  actions: {
    submit({ dispatch },{
      waWebContactId,
      waWebPhoneId,
      waWebChatId,
      waWebMessageId,
      data
    }) {
      return axios
        .baseRequest({
          method: "POST",
          url: "/api/waweb/messages/submit",
          params: {
            waWebContactId,
            waWebPhoneId,
            waWebMessageId,
            waWebChatId
          },
          data,
        })
        .then((d) => {
          dispatch('clearCachedData',{ item: d.data,key: "WAWEBMESSAGE_ADD" });
          return d.data
        });
    },
    getMedia({ dispatch },{
      waWebContactId,
      waWebPhoneId,
      waWebChatId,
      waWebMessageId,
      id,
      data
    }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "GET",
          url: `/api/waweb/messages/${id}/media`,
          params: {
            waWebContactId,
            waWebPhoneId,
            waWebMessageId,
            waWebChatId
          },
          data,
        },true)
        .then((d) => d.data);
    },
  },
  cacheKey: "WAWEBMESSAGE"
});

export default module;

