import axios from "../../../axios";
import { checkIdExist, storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/wa/contacts',{
  actions: {
    getCustomers({ dispatch },{ id,organisationId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/wa/contacts/${id}/customers`,
          params: { organisationId,force }
        })
        .then((d) => {
          return d.data;
        });
    },
    addCustomer({ dispatch },{ id,organisationId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: 'POST',
          url: `/api/wa/contacts/${id}/customers`,
          data: { organisationId },
          params:{force}
        })
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "FBWACONTACT"
});

export default module;

