<template>
  <div
    v-if="comment"
    class="simple-data-display"
    :class="{
      'not-read-comment': comment?.isRead != true,
      'comment-own-sent': comment?.ownerSent,
    }"
    @click="(e) => emitClick(e)"
  >
    <div class="comment-holder">
      <div class="comment-container" v-if="showPage && showPageInfo">
        <FbPageAvatar
          :pageId="comment.fbPageId"
          :displaySmall="true"
          :showImage="false"
        ></FbPageAvatar>
      </div>
      <FbCommentSimpleListAvatar
        class="parent-comment-container"
        v-if="showParentComment && parentComment"
        :organisationId="organisationId"
        :showParentComment="false"
        :comment="parentComment"
        :showPage="false"
        :showImage="false"
        :showDate="false"
        :displaySmall="true"
        :showPageImage="showPageImage"
      ></FbCommentSimpleListAvatar>
      <div class="simple-header">
        <div class="participant-container">
          <div v-if="showPageImage" class="page-image-display-container">
            <FbPageAvatar
              :pageId="comment.fbPageId"
              :showImageOnly="true"
            ></FbPageAvatar>
          </div>
          <FbUserAvatar
            :fbUserId="comment.fromId"
            :showImage="showImage && showPageImage != true"
            :displaySmall="displaySmall"
          >
            <i
              v-if="comment?.isRead != true"
              class="fa-solid fa-check"
              :class="{ 'not-read': comment?.isRead != true }"
            ></i>
            <i v-else class="fa-solid fa-check-double"></i>
            <span> {{ comment.message }}</span>
          </FbUserAvatar>
        </div>

        <div class="comment-info-container" v-if="showDate">
          <div class="comment-date-container">
            <span>{{ formatDate(comment.createdTime) }}</span>
          </div>
        </div>
      </div>
      <FbCommentList
        v-if="showChilds"
        class="child-list-container"
        :postId="comment.fbPostId"
        :parentId="comment._id"
        :showParentAvatar="false"
        :showMainPost="false"
        :hideHeader="true"
        :displaySimple="true"
        :navigateOnClick="false"
        :displayReverse="true"
        :paginateScroll="true"
        :showActionColumn="false"
        :showPage="false"
        :hideIfEmpty="true"
      ></FbCommentList>
    </div>
  </div>
</template>

<script>
import FbUserAvatar from "@/components/facebook/FbUserAvatar";
import FbPageAvatar from "@/components/facebook/FbPageAvatar.vue";
import FbCommentList from "@/components/facebook/FbCommentList.vue";

import { formatDate } from "@/helpers/common";
export default {
  name: "FbCommentListAvatar",
  props: {
    organisationId: { type: String },
    comment: { type: Object },
    showPage: { type: Boolean, default: () => true },
    showImage: { type: Boolean, default: () => true },
    showDate: { type: Boolean, default: () => true },
    displaySmall: { type: Boolean, default: () => false },
    showParentComment: { type: Boolean, default: () => false },
    showChilds: { type: Boolean, default: () => false },
    showPageInfo: { type: Boolean, default: () => true },
    showPageImage: { type: Boolean, default: () => true },
  },
  data() {
    return {
      parentComment: undefined,
    };
  },
  components: {
    FbUserAvatar,
    FbPageAvatar,
    FbCommentList,
  },
  watch: {
    comment() {
      this.getParentComment();
    },
  },
  mounted() {
    this.getParentComment();
  },
  emits: ["itemClick"],
  methods: {
    getParentComment() {
      if (
        this.showParentComment != true ||
        this.comment == null ||
        this.comment.parentCommentId == null
      ) {
        this.parentComment = null;
        return;
      }
      return this.$store
        .dispatch("fbComment/getById", {
          id: this.comment.parentCommentId,    mergeRequest: true
        })
        .then((d) => {
          this.parentComment = d;
        });
    },
    formatDate(val) {
      return formatDate(val, undefined, true);
    },
    emitClick(e) {
      this.$emit("itemClick", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-date-container {
  display: flex;
  align-items: flex-end;
  padding: 0 0.5rem;
  font-size: 0.7rem;
  font-weight: 400;

}

.simple-data-display {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 0.5rem;
  cursor: pointer;
  max-width: 20rem;
  @media (max-width: 800px) {
      max-width: 100%;
    }
    
  &.comment-own-sent {
    margin-left: auto;
  }

  .participant-container {
    display: flex;
  }

  .child-list-container {
    margin-left: 0.5rem;
  }
  .simple-header {
    display: flex;
    flex-direction: row;
  }

  .comment-container {
    justify-content: center;
    display: flex;
  }

  .comment-info-container {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .comment-type-container {
      justify-content: center;
      display: flex;
      font-size: 1.2rem;
      align-items: center;
      padding: 0.3rem;
      margin-left: auto;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  &::v-deep(.parent-comment-container) {
    margin: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  &::v-deep(.simple-data-display) {
    &::before {
      display: none;
    }
  }
}
</style>