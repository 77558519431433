import axios from "../../../axios";
import { checkIdExist,storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/waweb/labels',{
  actions: {
    addFromChat({ dispatch },{ id,waWebChatId }) {
      checkIdExist(id,{ dispatch });
      checkIdExist(waWebChatId,{ dispatch });
      return axios
        .baseRequest({
          method: "PATCH",
          url: `/api/waweb/labels/${id}/chats/${waWebChatId}`,
        })
        .then((d) => d.data);
    },
    removeFromChat({ dispatch },{ id,waWebChatId }) {
      checkIdExist(id,{ dispatch });
      checkIdExist(waWebChatId,{ dispatch });
      return axios
        .baseRequest({
          method: "DELETE",
          url: `/api/waweb/labels/${id}/chats/${waWebChatId}`,
        })
        .then((d) => d.data);
    },
    getChats({ dispatch },{ id,params }) {
      checkIdExist(id,{ dispatch });
      params = params ?? {};
      const reqOptions = params?.options;
      return axios
        .baseRequest({
          method: "GET",
          url: `/api/waweb/labels/${id}/chats`,
          params: { ...params,...reqOptions,force: true }
        })
        .then((d) => d.data);
    },
  },
  cacheKey: "WAWEBLABEL"
});

export default module;