<template>
  <div v-if="readyToDisplay" class="avatar-container"
    :class="{ 'avatar-container-sm': displaySmall,'show-in-card': showInCard }">
    <div class="avatar-img-container" v-if="showImage || showImageOnlyDesc != undefined">
      <CustomTooltip :text="showImageOnlyDesc ?? '-'" position="bottom" :active="showImageOnlyDesc != undefined">
        <div v-if="imageUrl" :style="{ 'background-image': 'url(' + imageUrl + ')' }" class="avatar-cover-background">
        </div>
        <div v-else class="avatar-icon-background">
          <slot name="defaultImage">
            <i class="fa-solid fa-file"></i>
          </slot>
        </div>
      </CustomTooltip>
    </div>
    <div class="avatar-details" v-if="showImageOnlyDesc == undefined" @click="(e) => emitClick(e)">
      <div class="main-data-container">
        <slot name="mainData"></slot>
      </div>
      <div class="data-details-container">
        <slot name="dataDetails"></slot>
      </div>
    </div>
    <slot name="additionalContainers" v-if="showImageOnlyDesc == undefined"></slot>
    <div class="button-container" v-if="showImageOnlyDesc == undefined">
      <CustomDropdown v-if="actionMenuOptions && showActionMenu" :displayMini="true" :showArraw="false"
        :options="actionMenuOptions" @optionSelected="(eventData) => clickActionMenu(eventData)">
        <template v-slot:buttonContainer>
          <CustomButton :displayMini="true" buttonClass="flat-button" iconClass="fa-solid fa-bars"></CustomButton>
        </template>
      </CustomDropdown>

      <CustomPopup v-if="showDetails" :title="$t('general.details')">
        <template v-slot:buttonContainer>
          <i class="fa-solid fa-circle-info"></i>
        </template>
        <div>
          <CustomButton class="action-button" iconClass="fa-solid fa-rotate" @click="(e) => refreshClick(e)">
          </CustomButton>
          <div class="custom-data-display">
            <slot name="popupData"></slot>
          </div>
        </div>
      </CustomPopup>

      <slot name="additionalButtons"></slot>

      <div v-if="showLink && hasLink" class="external-link-container">
        <CustomButton buttonClass="flat-button" @click="(e) => linkClick(e)">
          <slot name="externalButton">
            <i class="fa-brands fa-facebook"></i>
          </slot>
        </CustomButton>
      </div>
    </div>
  </div>
  <div v-else class="avatar-container unknown-avatar" @click="(e) => emitClick(e)">
    <div class="avatar-img-container animated-image" v-if="showImage || showImageOnlyDesc != undefined"></div>
    <div class="avatar-details " v-if="showImageOnlyDesc == undefined">
      <div class="main-data-container">
        <div class="animated-line"></div>
      </div>
      <div class="data-details-container">
        <div class="animated-details"></div>
      </div>
    </div>
    <div class="button-container" v-if="showImageOnlyDesc == undefined">
      <div class="animated-button"></div>
      <div class="animated-button"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CustomAvatar",
  components: {},
  props: {
    readyToDisplay: { type: Boolean,default: () => false },
    imageUrl: { type: String },
    actionMenuOptions: { type: Array },
    showLink: { type: Boolean,default: () => false },
    hasLink: { type: Boolean,default: () => false },
    showDetails: { type: Boolean,default: () => false },
    showInCard: { type: Boolean,default: () => false },
    showImage: { type: Boolean,default: () => true },
    showImageOnlyDesc: { type: String,default: () => undefined },
    displaySmall: { type: Boolean,default: () => false },
    showActionMenu: { type: Boolean,default: () => false },
  },
  emits: ["itemClick","actionMenuClick","refreshClick","linkClick"],
  methods: {
    emitClick(event) {
      this.$emit("itemClick",event);
    },
    clickActionMenu(eventData) {
      this.$emit("actionMenuClick",eventData);
    },
    refreshClick(event) {
      this.$emit("refreshClick",event);
    },
    linkClick(event) {
      this.$emit("linkClick",event);
    },
  },

};
</script>

<style lang="scss" scoped>
.clickable-avatar {
  &>.avatar-details:hover {
    background-color: rgba($color: black, $alpha: 0.1);
    border-radius: 1rem;
  }
}

.show-in-card {
  display: inline-flex;
  flex-direction: row;
  margin: 0.2rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
  background:rgb(var(--content-bgcolor));
}

.avatar-details {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
  cursor: pointer;

  &>* {
    padding: 0 0.2rem;
  }

  .main-data-container {
    font-size: 1rem;
    font-weight: 500;
  }

  .data-details-container {
    font-size: 0.8rem;
    color: gray;

    &::v-deep(button) {
      font-size: 0.8rem;
      color: gray;
    }
  }
}

.avatar-container {
  display: inline-flex;
  &.avatar-container-sm {
    .avatar-img-container {
      width: 2rem;
      height: 2rem;

      .material-icons {
        font-size: 1rem;
      }
    }

    .avatar-details {
      .main-data-container {
        font-size: 0.8rem;
      }

      .data-details-container {
        font-size: 0.6rem;
      }
    }
  }

  .button-container {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::v-deep(.vs-button) {
      .fa-brands {
        font-size: 1.2rem;
      }
    }
  }

  .avatar-img-container {
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    padding: 0;
    margin: 1px;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.15) !important;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;

    &::v-deep(.tooltip-container) {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .tooltip-face {
        width: 100%;
        height: 100%;
        padding: 0;
      }
    }
  }

  .avatar-cover-background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
  }

  .avatar-icon-background {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.unknown-page-avatar {
    opacity: 0.9;
  }

  .main-data-container {
    font-size: 1rem;
    font-weight: 500;
  }

  .external-link-container {
    display: inline-flex;
    align-items: center;
  }

  &.unknown-avatar {
    opacity: 0.9;

    .avatar-details {
      color: gray;
    }
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }

  100% {
    background-position: 468px 0
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.animated-image {
  @extend .animated-background;
}

.animated-button,
.animated-details,
.animated-line {
  @extend .animated-background;
  height: 1.1rem;
  width: 10rem;
  margin: 0.3rem;
  border-radius: 0.5rem;
}

.animated-button {
  width: 1.5rem;
}

.animated-details {
  height: .9rem;
}
</style>