import axios from "@/axios";
import { checkIdExist, storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/ig/media/tagged',{
  actions: {
    getMentioned({ dispatch },{ id,organisationId,pageId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest(
          {
            url: `/api/ig/media/tagged/${id}/mentioned`,
            params: {
              organisationId,
              pageId,
              force,
            },
          },
          !(force == true)
        )
        .then((d) => {
          return d.data;
        });
    },
    submitReply({ dispatch },{ id,message,tokenId }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/ig/media/tagged/${id}/comments`,
          params: { tokenId },
          data: { message },
        })
        .then((d) => {
          return d.data;
        });
    },
    getComments({ dispatch },{ id,organisationId,pageId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest(
          {
            url: `/api/ig/media/tagged/${id}/comments`,
            params: {
              organisationId,
              pageId,
              force,
            },
          },
          !(force == true)
        )
        .then((d) => {
          return d.data;
        });
    },
    getChildren({ dispatch },{ id,organisationId,pageId,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest(
          {
            url: `/api/ig/media/tagged/${id}/children`,
            params: {
              organisationId,
              pageId,
              force,
            },
          },
          !(force == true)
        )
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "FBIGTAGGEDMEDIA"
});

export default module;
