<template>
  <div v-if="waWebChat" class="simple-data-display" :class="{
    'not-read-waWebChat': waWebChat?.unreadCount > 0,
  }" @click="(e) => emitClick(e)">
    <div class="phone-container" v-if="showPhoneInfo">
      <WaWebPhoneAvatar :displaySmall="true" :id="waWebChat.waWebPhoneId"></WaWebPhoneAvatar>
      <div class="waweb-chat-info-container">
        <div class="waweb-chat-type-container">
          <div class="unread" v-if="waWebChat.isAnswered == false">
            <span class="numb danger-numb"><i class="fa-solid fa-exclamation"></i></span>
          </div>
          <div class="unread" v-if="waWebChat.unreadCount">
            <span class="numb">{{ waWebChat.unreadCount }}</span>
          </div>
          <div class="waweb-chat-date-container">
            <span>{{ formatDate(waWebChat.lastMessageDate) }}</span>
          </div>
          <i class="fa-brands fa-whatsapp"></i>
        </div>
      </div>
    </div>
    <div class="simple-header">
      <div class="chat-container">
        <CustomAvatar :imageUrl="imageUrl" :readyToDisplay="waWebChat != null" :dataIdentifier="waWebChat._id">
          <template v-slot:mainData>
            {{ name }}
          </template>
          <template v-slot:dataDetails>
            <div class="last-message" v-if="lastMessage">
              <i v-if="lastMessage?.isRead != true" class="fa-solid fa-check not-read"></i>
              <i v-else class="fa-solid fa-check-double"></i>
              <span> {{ lastMessage?.data?.body }}</span>
            </div>
          </template>
        </CustomAvatar>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/helpers/common";
import WaWebPhoneAvatar from "../whatsappWeb/WaWebPhoneAvatar.vue";
import CustomAvatar from "../CustomAvatar.vue";

export default {
  name: "WaWebChatSimpleListAvatar",
  props: {
    waWebChat: { type: Object },
    showPhoneInfo: { type: Boolean,default: () => true },
    showPageImage: { type: Boolean,default: () => false },
  },
  data() {
    return {
      lastMessage: undefined,
      name: undefined,
      imageUrl: undefined,
    };
  },
  components: {
    WaWebPhoneAvatar,
    CustomAvatar
  },
  watch: {
    waWebChat() {
      this.updateData();
    },
    showPageImage() {
      this.updateData();
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    async updateData() {
      if (this.waWebChat) {

        this.name = this.waWebChat.name;
        if (this.waWebChat.waWebContactIds && this.waWebChat.waWebContactIds.length == 1) {
          this.$store.dispatch("waWebContact/getById",{
            id: this.waWebChat.waWebContactIds[0]
          }).then(contact => {
            this.imageUrl = contact?.imageUrl;
            this.name = contact?.name;
          })
        }

        if (this.waWebChat?.lastMessageId) {
          this.$store
            .dispatch("waWebMessage/getById",{
              id: this.waWebChat.lastMessageId,
              mergeRequest: true
            })
            .then((d) => {
              this.lastMessage = d;
            })
            .catch(() => null);
        }
      }
    },
    isValidContent(text) {
      return text && text.length > 0;
    },
    formatDate(val) {
      return formatDate(val,undefined,true);
    },
    emitClick(e) {
      this.$emit("itemClick",e);
    },
  },
};
</script>

<style lang="scss" scoped>
.unread {
  .numb {
    &.danger-numb {
      background: inherit;
      color: inherit;
    }

    background: #25d366;
    color: #fff;
    font-weight: 500;
    min-width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    margin-right: 0.4rem;
  }
}

.waweb-chat-date-container {
  display: flex;
  align-items: flex-end;
  padding: 0 0.5rem;
  font-size: 0.7rem;
  font-weight: 400;


}

.simple-data-display {
  display: flex;
  overflow: hidden;
  flex: 1 0;
  flex-direction: column;
  border-radius: 0.5rem;
  cursor: pointer;
  max-width: 20rem;

  @media (max-width: 800px) {
    max-width: 100%;
  }

  &::v-deep(.avatar-container) {
    max-width: 100%;
    overflow: hidden;
    flex: 1 1 auto;

    .avatar-details {
      overflow: hidden;
      flex: 1 1 auto;
    }

    .main-data-container {
      font-size: 0.8rem;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .data-details-container {
      display: flex;
      align-items: center;

      .last-message {
        flex: 1 1 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .lastcommentsender {
        margin-left: auto;
        max-width: 100%;
      }
    }
  }

  .simple-header {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    max-width: 100%;
    width: 100%;

    .chat-container {
      display: flex;
      overflow: hidden;
      flex: 1 1 auto;
    }
  }

  .phone-container {
    justify-content: center;
    display: flex;
    max-width: 100%;
    width: 100%;
  }

  .waweb-chat-info-container {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .waweb-chat-type-container {
      justify-content: center;
      display: flex;
      font-size: 1.2rem;
      align-items: center;
      padding: 0.3rem;
      margin-left: auto;
    }
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>