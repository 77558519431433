<template>
  <WaWebLabelAvatar :initialData="data" :showInfo="false"></WaWebLabelAvatar>
    <div class="waweb-label-container" v-if="data">
      <vs-tabs alignment="center" class="count-container">
        <vs-tab :label="$t('general.details')">
          <div class="custom-data-display">
            <div v-html="getObjectDefaultDisplay()"></div>
          </div>
        </vs-tab>
        <vs-tab :label="$t('general.waWebChats')">
          <WaWebChatList
            :organisationId="organisationId"
            :waWebPhoneId="waWebPhoneId"
            :waWebLabelIds="[dataId]"
          ></WaWebChatList>
        </vs-tab>
      </vs-tabs>
    </div>
</template>

<script>

import { displayObjectData } from '../../helpers/common';
import WaWebChatList from './WaWebChatList.vue';

export default {
  components: { WaWebChatList },
  props: {
      initialData: undefined,
      id: undefined,
      organisationId: undefined,
      waWebPhoneId: undefined,
  },
  data() {
    return {
      data: undefined
    };
  },
  watch: {
    id() {
      this.getWaWebChat();
    },
    initialData() {
      this.getWaWebChat();
    },
  },
  computed: {
    dataId() {
      return this.data?._id ?? this.initialData?._id ?? this.id;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(force = false) {
      if (force != true && this.initialData != null) {
        this.data = this.initialData;
        return this.data;
      }
      return this.$store
        .dispatch("waWebChat/getById", {
          id: this.id,
          organisationId: this.organisationId,
          phoneId: this.phoneId,
          force,
        })
        .then((d) => {
          this.data = d;
        });
    },
    getObjectDefaultDisplay() {
      return displayObjectData(this.data?.data ?? {});
    },
  },
};
</script>
