
<template>
    <div v-if="data">
      <div v-if="displayType == 'mini'" 
      class="mini-label"
      @click="showPopupData"> 
      {{ data.name ?? data.data?.page_label_name}}
      </div>
      <CustomAvatar
      v-else
        class="fb-label-avatar"
        :readyToDisplay="data != null"
        :dataIdentifier="id"
        :displaySmall="displaySmall"
        :showDetails="showDetails"
        :showImage="showImage"
        :actionMenuOptions="menuOptions"
        @actionMenuClick="clickMenuAction"
        @itemClick="navigateToInstaUser"
        @refreshClick="getLabel(true)"
      >
        <template v-slot:defaultImage>
          <span class="label-icon" >
            <i class="fa-solid fa-tag"></i>
          </span>
        </template>
        <template v-slot:mainData>
          {{ data.name ?? data.data?.page_label_name}}
        </template>
        <template v-slot:dataDetails>
        </template>
        <template v-if="showInfo" v-slot:popupData>
          <FbLabelDetails :initialData="data"></FbLabelDetails>
        </template>
      </CustomAvatar>
      <CustomPopup ref="labelDataPopup" :showButton="false">
          <FbLabelDetails :initialData="data"></FbLabelDetails>
      </CustomPopup>
   </div>
</template>

<script>
import FbLabelDetails from "./FbLabelDetails.vue";

export default {
  name: "FbLabelAvatar",
  components: { FbLabelDetails },
  props: {
    id: { type: String },
    initialData: { type: Object },
    showInfo: { type: Boolean,default: () => true },
    showDetails: { type: Boolean,default: () => true },
    displaySmall: { type: Boolean,default: () => false },
    showImage: { type: Boolean,default: () => true },
    displayType: { type: String },
  },
  data() {
    return {
      data: undefined,
      menuOptions: [],
    };
  },
  watch: {
    "$store.state.lastUpdateNotification"(val) {
      if (
        val &&
        this.data &&
        val.objectType == "FBLABEL_UPDATED" &&
        val.data &&
        Array.isArray(val.data) &&
        val.data.find((x) => x._id == this.data._id)
      ) {
        this.getLabel();
      }
    },
    id() {
      this.getLabel();
    },
    initialData() {
      this.getLabel();
    },
  },
  mounted() {
    this.getLabel();
  },
  methods: {
    clickMenuAction(){},
    showPopupData() {
      this.$refs.labelDataPopup?.showPopup();
    },
    getLabel(force=false) {
      if (force != true && this.initialData != null) {
        this.data = this.initialData;
        return;
      }
      this.$store
        .dispatch("fbLabel/getById",{
          id: this.id,
           mergeRequest: true,
          force
        }).then(d => {
          this.data = d;
        });
    },
      navigateToInstaUser() {
      },
    },
};
</script>

<style lang="scss" scoped>
.label-icon{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(var(--vs-primary));
  color: white;
  i{
    margin-top: 2px;
  }
}

.fb-label-avatar{
    display: inline-flex;
}

.mini-label{
  background-color: rgb(var(--vs-primary));
  color: white;

  display: inline-flex;
  border-radius: .5rem;
  padding: .3rem .5rem;
  margin: .2rem .3rem;
  font-size:.7rem;
  font-weight: 600;
  cursor: pointer;
}
</style>